import React, { useEffect, useState } from "react";
import modalTemplateIcon from "../../../../assets/modal-template-icon.svg";
import arrowDownIcon from "../../../../assets/arrow-down-icon.svg";
import starIcon from "../../../../assets/Star.svg";
import arrowIcon from "../../../../assets/arrow-icon.svg";

const freeTemplates = [
  {
    title: "Basic Template",
    image: "templateImages/basic.svg",
    bullets: [
      "Non-specialised design that fits any product.",
      "Includes essential sections for showcasing product features.",
      "Optimized for simplicity.",
      "Perfect for testing or starting your first product landing page",
    ],
    value: "default",
  },
];
const proTemplates = [
  {
    title: "Health Product Template",
    image: "templateImages/health-product.svg",
    bullets: [
      "Designed for health and wellness products.",
      "Highlights benefits, ingredients, and comparisons.",
      "Sections for certifications or safety information.",
      "Professional design to build trust and credibility with customers.",
    ],
    value: "pro",
  },
  {
    title: "Pets & Kids Template",
    image: "templateImages/pets-kids.svg",
    bullets: [
      "Tailored for products related to pets, children, or families.",
      "Engaging, friendly design.",
      "Focus on user testimonials and relatable stories to connect with buyers.",
      "Showcase safety, fun, and quality.",
    ],
    value: "kids",
  },
];

const TemplateSelector = ({ subscriptionStatus, template, setTemplate }) => {
  const [collapsed, setCollapsed] = useState(false);

  const goToUpgrade = () => {
    window.location.href = "/subscriptions";
  };

  const handleTemplateSelect = (template) => {
    setTemplate(template);
    setCollapsed(true);
  };

  useEffect(() => {
    if (!template) {
      setCollapsed(false);
    }
  }, [template]);

  return (
    <div className="pr-[10px] w-full">
      <div className="w-full border border-[#E0E0E0] rounded-lg p-4 flex flex-col gap-2">
        <div
          className="sticky flex flex-row justify-between items-center pr-4 cursor-pointer select-none gap-4"
          onClick={() => setCollapsed(!collapsed)}
        >
          <div className="flex flex-row items-start gap-4">
            <img src={modalTemplateIcon} alt="modalTemplateIcon" />
            <div>
              <div className="modal-content-container">
                <h4>Select product template</h4>
                <p
                  className={`${template && "!text-[#6941C6] !font-semibold"}`}
                >
                  {template
                    ? template.title
                    : "Your product page will be optimized for your industry, and structured to drive conversions and enhance customer trust."}
                </p>
              </div>
            </div>
          </div>
          <img
            src={arrowDownIcon}
            alt="arrow down icon"
            className={`w-[20px] ${!collapsed && "transform rotate-180"}`}
          />
        </div>
        {!collapsed && (
          <div className="modal-content-container wide flex flex-col overflow-scroll w-full p-2">
            <h4>Free templates</h4>
            <div className="flex flex-row gap-4 flex-wrap">
              {freeTemplates.map((freeTemplate, index) => (
                <div
                  key={index}
                  className={`template-card outline ${
                    freeTemplate.value === template?.value
                      ? "outline-[#7F56D9] outline-2"
                      : "outline-[#E3E6EA] outline-1"
                  } rounded-lg cursor-pointer pr-4 overflow-hidden flex gap-4 w-[580px] min-w-[580px]`}
                  onClick={() => handleTemplateSelect(freeTemplate)}
                >
                  <img
                    src={freeTemplate.image}
                    alt={freeTemplate.title}
                    className="h-full"
                  />
                  <div className="template-card-content my-4 flex flex-col gap-4 justify-between">
                    <div className="">
                      <h4 className="mb-2">{freeTemplate.title}</h4>
                      <ul>
                        {freeTemplate.bullets.map((bullet, index) => (
                          <li className="mb-[2px] text-[#475467]" key={index}>
                            {bullet}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <button
                      className={`select-template-button ${
                        freeTemplate.value === template?.value && "selected"
                      }`}
                    >
                      {freeTemplate.value === template?.value
                        ? "Selected template"
                        : "Select template"}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!collapsed && (
          <div className="modal-content-container wide flex flex-col overflow-scroll w-full p-2">
            <div className="flex flex-row gap-4 flex-wrap items-center">
              <h4>Pro templates</h4>
              {!subscriptionStatus && (
                <div className="upgrade-status regular" onClick={goToUpgrade}>
                  <span>Unlock all Pro templates</span>
                  <div className="button">
                    <span>Upgrade</span>
                    <img
                      className="arrow-icon"
                      src={arrowIcon}
                      alt="arrow pointing right"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-row gap-4 flex-wrap">
              {proTemplates.map((proTemplate, index) => (
                <div
                  key={index}
                  className={`template-card outline ${
                    proTemplate.value === template?.value
                      ? "outline-[#7F56D9] outline-2"
                      : "outline-[#E3E6EA] outline-1"
                  } rounded-lg cursor-pointer pr-4 overflow-hidden flex gap-4 w-[580px] min-w-[580px]`}
                  onClick={() =>
                    !subscriptionStatus
                      ? goToUpgrade()
                      : handleTemplateSelect(proTemplate)
                  }
                >
                  <img
                    src={proTemplate.image}
                    alt={proTemplate.title}
                    className="h-full"
                  />
                  <div className="template-card-content my-4 flex flex-col gap-4 justify-between">
                    <div className="">
                      <h4 className="mb-2">{proTemplate.title}</h4>
                      <ul>
                        {proTemplate.bullets.map((bullet, index) => (
                          <li className="mb-[2px] text-[#475467]" key={index}>
                            {bullet}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <button
                      className={`select-template-button ${
                        proTemplate.value === template?.value && "selected"
                      }`}
                    >
                      {!subscriptionStatus ? (
                        <div className="flex flex-row gap-2 items-center">
                          <img
                            src={starIcon}
                            alt="star icon"
                            width={16}
                            height={16}
                            className="upgrade-plan-icon mt-[-1px]"
                          />
                          <span>Unlock with Unlimited plan</span>
                        </div>
                      ) : (
                        <span>
                          {proTemplate.value === template?.value
                            ? "Selected template"
                            : "Select template"}
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateSelector;
