

export default function checkPrentRowAndKeys(properties) {
    const removeKeys = ['Price', 'SalePrice', 'key', 'children','status','enableSalePrice','SKU',"grossProfit","parent"];
// console.log(properties,'in parent cehckl');

    let keys = {};
    let parent = false
    for (let key in properties) {
        if (!removeKeys.includes(key)) {
            let parts = properties[key].split(',')
            
            if (parts.length > 1) {
                parent = true
            }
            keys = { ...keys, [key]: properties[key] }
        }
    }

    return {keys, parent}
}