import arrowIcon from "../../../assets/arrow-icon.svg";

const ProductSlotCounterButton = ({ allProductCount }) => {
  const goToUpgrade = () => {
    window.location.href = "/subscriptions";
  };

  return (
    <div
      className={`upgrade-status ${
        5 - allProductCount >= 3 ? "regular" : "error"
      }`}
      onClick={goToUpgrade}
    >
      <span>
        {5 - allProductCount > 0
          ? 5 - allProductCount + " products left"
          : "Product limit reached"}{" "}
      </span>
      <div className="button">
        <span>Upgrade for unlimited products</span>
        <img
          className="arrow-icon"
          src={arrowIcon}
          alt="arrow pointing right"
        />
      </div>
    </div>
  );
};

export default ProductSlotCounterButton;
