import eye from "../../../../assets/eye.svg";
import eyeOff from "../../../../assets/eye-off.svg";
import dragIcon from "../../../../assets/drag-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLabelOfProperties,
  changePositionOfVariant,
  changePositionOfVariantInOtherState,
  disableSpecificShipsfromOnFirstStep,
  disableVariant,
} from "../../../../redux/slices/product-variants-slice";
import React, { useEffect, useState } from "react";
import { Input, Tooltip } from "antd";
import { useDrag, useDrop } from "react-dnd";

const VariantsPropertiesContainer = ({
  index,
  value,
  property,
  disable,
  shipsFrom,
  aliexpressSkuObject,
  localPropertiesLabel,
  setLocalPropertiesLabel,
  setUpdatePropertyName,
}) => {
  const dispatch = useDispatch();

  const { productData, shipsFromArray } = useSelector(
    (store) => store.productVariants
  );
  const { data } = productData;
  const { product_variants } = data;
  const [duplicateLabelNameError, setDuplicateLabelNameError] = useState({
    error: false,
    index: "",
  });

  

  const handlDisable = (is_disabled) => {
    console.log("is disbaled true => ", is_disabled);
  
    // if (shipsFrom) {
    //   dispatch(
    //     disableSpecificShipsfromOnFirstStep({
    //       property_name: property,
    //       property_value: value,
    //       shipsFrom,
    //       is_disabled: is_disabled,
    //     })
    //   );
    // } else {
      dispatch(disableVariant({ value, property, is_disabled,shipsFrom }));
    // }
  };

  // drag and drop
  const moveVariant = React.useCallback(
    (dragIndex, hoverIndex) => {
      // console.log('indexes :::', dragIndex, hoverIndex);
      // dispatch(changePositionOfVariant({ from: dragIndex, to: hoverIndex }))
      dispatch(
        changePositionOfVariantInOtherState({
          from: dragIndex,
          to: hoverIndex,
          property,
        })
      );
    },
    [property]
  );

  // local state updating
  const handlePropertyLabelChange = (property, value, index) => {
    if (value!==property && localPropertiesLabel.hasOwnProperty(value)) {
      setDuplicateLabelNameError({ error: true, index: index });
      //  return
    } else {
      setDuplicateLabelNameError({ error: false, index: "" });
    }
    setLocalPropertiesLabel({
      ...localPropertiesLabel,
      [property]: value,
    });
    console.log("here", localPropertiesLabel[property]);
  };

  // Sync local label to Redux only on blur
  const handlePropertyLabelBlur = (property, Mainproperty, newValue) => {
    const trimmedLabel = localPropertiesLabel[property]?.trim();
    if (localPropertiesLabel.hasOwnProperty(trimmedLabel)) {
      setDuplicateLabelNameError({ error: false, index: "" });
      setLocalPropertiesLabel((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
      return;
    } else if (trimmedLabel && trimmedLabel !== property) {
      dispatch(
        changeLabelOfProperties({
          property: Mainproperty,
          propertyValue: property,
          value: newValue,
        })
      );
      setUpdatePropertyName((prev)=>!prev);
      setLocalPropertiesLabel((prevLabels) => ({
        ...prevLabels,
        [property]: trimmedLabel, // Reset to the default name if the input is empty
      }));
    } else if (!trimmedLabel) {
      setLocalPropertiesLabel((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
    }
  };

  return (
    <VariantDragCard
      moveImage={moveVariant}
      id={index}
      index={index}
      // id={aliexpressSkuObject[value]}
      // index={aliexpressSkuObject[value]}
    >
      {React.Children.toArray(
        <div
          className={`${
            disable && "border-[#cad1d7]"
          } variants-edit-control-container`}
        >
          <img
            style={{
              cursor: "grab",
            }}
            src={dragIcon}
            alt=""
          />
          {/* <span className={`${disable && "bg-[#cad1d7]"} eye-span`}></span> */}

          <Input
            value={localPropertiesLabel[value]}
            disabled={disable}
            
            onChange={(e) => handlePropertyLabelChange(value, e.target.value, index)}
            onBlur={(e) => handlePropertyLabelBlur(value, property, e.target.value)}
            className={`${
              duplicateLabelNameError.error &&
              duplicateLabelNameError.index == index
                ? "  !text-[red]"
                : " "
            } ${
              disable && "text-[#a5afbb] custom-disabled-input"
            } disabled:bg-none   !shadow-none !border-0 focus:!border-0 focus:!outline-0 !px-0`}
          />
         
          <span className={`${disable && "bg-[#cad1d7]"} eye-span`}></span>
          <Tooltip
            title={
              shipsFromArray?.length > 0 && !shipsFrom
                ? "Please select the origin country (Ships from) in Step 2 Variant Pricing"
                : ""
            }
            overlayClassName="custom-tooltip-variant-propertry"
          >
            {disable ? (
              <img
                style={{
                  cursor:
                    shipsFromArray?.length > 0 && !shipsFrom
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={() =>
                  shipsFromArray?.length > 0 && !shipsFrom
                    ? null
                    : handlDisable(false)
                }
                src={eyeOff}
                alt=""
              />
            ) : (
              <img
                style={{
                  cursor:
                    shipsFromArray?.length > 0 && !shipsFrom
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={() =>
                  shipsFromArray?.length > 0 && !shipsFrom
                    ? null
                    : handlDisable(true)
                }
                src={eye}
                alt=""
              />
            )}
          </Tooltip>
        </div>
      )}
      {duplicateLabelNameError.error &&
        duplicateLabelNameError.index == index && (
          <p className="text-[8px] text-[red] mb-0 ">
            property already exists!
          </p>
        )}
    </VariantDragCard>
  );
};
export default VariantsPropertiesContainer;
const VariantDragCard = ({ children, id, index, moveImage }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: "image",
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // moveImage(dragIndex, hoverIndex);
      // item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      console.log(item);

      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex !== hoverIndex) {
        moveImage(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });
  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  useEffect(() => {
    if (isDragging) {
      document.body.style.setProperty("cursor", "grabbing", "important");
    } else {
      document.body.style.setProperty("cursor", "default", "important");
    }
    return () => {
      document.body.style.setProperty("cursor", "default", "important");
    };
  }, [isDragging]);

  return (
    <div
      ref={ref}
      style={{ opacity, cursor: isDragging ? "grabbing" : "grab" }}
    >
      {children}
    </div>
  );
};
