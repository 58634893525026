import React, { useState } from "react";

const ProTemplateTexOnImage = ({
  textOnImageDetails,
  companyName,
  handleSectionEditChange,
  textOnImageSection,
  companyNameSection,
}) => {
  const containerStyle = {
    backgroundImage: `url(${textOnImageDetails.img})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "460px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    textAlign: "center",
    fontSize: "2rem",
    position: "relative",
    padding: '50px 10%'
  };

  return (
    <>
      {/* <div class="pro-temp-scrolling-container">
        <div class="pro-temp-scrolling-text">
          <h1
            onClick={() => handleSectionEditChange(["companyNameSection"])}
            className={`min-w-[800px] min-h-[30px] ${
              companyNameSection ? "outline-border-selected" : "outline-border"
            }`}
          >
            {Array.from({ length: 20 }, () => `${companyName} `)}
          </h1>
        </div>
      </div> */}
      <div
        onClick={() => handleSectionEditChange(["textOnImageSection"])}
        className={` pro-temp-text-on-img  mt-[66px] ${textOnImageSection ? "outline-border-selected" : "outline-border"
          }`}
        style={containerStyle}
      >
        <div className="pro-temp-text-container">
          <h2>{textOnImageDetails.title}</h2>
          <p>{textOnImageDetails.details}</p>
        </div>
      </div>
    </>
  );
};

export default ProTemplateTexOnImage;
