import americanExpress from "../../../assets/americanExpress.svg";
import visa from "../../../assets/visa.svg";
import mastercard from "../../../assets/mastercard.svg";

const GuaranteedViewBox = () => {
  return (
    <div className="product-payment-method-box">
      <span className="product-method-hd">Guaranteed safe checkout</span>
      <div className="product-payment-method-images-container">
        <img src={visa} alt="visa" />
        <img src={mastercard} alt="mastercard" />
        <img src={americanExpress} alt="americanExpress" />
      </div>
    </div>
  );
};
export default GuaranteedViewBox;
