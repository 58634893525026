import { Tabs } from "antd";
import { useState } from "react";

const { TabPane } = Tabs;

const ProTemplateTabs = ({
  tabsContent,
  tabsSection,
  handleSectionEditChange,
}) => {
  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div
      onClick={() => handleSectionEditChange(["tabsSection"])}
      className={`w-full ${
        tabsSection ? "outline-border-selected" : "outline-border"
      }`}
    >
      <Tabs activeKey={activeKey} onChange={handleTabChange}>
        {tabsContent.map((content, index) => {
          return(
            <TabPane tab={`${content.heading}`} key={index + 1}>
              {content.details}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};
export default ProTemplateTabs;
