const ProTemplateCollpseContentEditComponent = ({
  FAQData,
  setFAQData,
  collapseIndex,
}) => {
  console.log('data.prod',collapseIndex, FAQData);
  
  return (
    <>
      <div className="title-container">Collapse Content</div>
      <div className="input-main-container">
        <div className="input-container">
          <label className="input-label">Heading</label>
          <input
            className="form-input"
            type="text"
            value={FAQData?.data[collapseIndex].title}
            onChange={(e) =>
              setFAQData((prev) => {
                const newData = [...prev.data]; 
                newData[collapseIndex] = { 
                  ...newData[collapseIndex],  
                  title: e.target.value,  
                };
                return {
                  // ...prev,
                  data: newData,  
                };
              })
            }
          />
        </div>
        <div className="input-container">
          <label className="input-label">Detail</label>
          <input
            className="form-input"
            type="text"
            value={FAQData?.data[collapseIndex].details}
            onChange={(e) =>
              setFAQData((prev) => {
                const data = prev.data;
                data[collapseIndex]={
                  ...data[collapseIndex],
                  details :e.target.value
                };
                return {
                  // ...prev,
                  data: data,
                };
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default ProTemplateCollpseContentEditComponent;
