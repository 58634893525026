import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import canvaIcon from '../../assets/canva.svg'
const ProTemplateFirstContentBox = ({
    text,
    heading,
    handleSectionEditChange,
    image,
    section1Image,
    salesTextHeadline1,
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    
    const [content, setcontent] = useState();
    useEffect(() => {
      setcontent({
        text: text ,
        heading: heading,
      });
    }, [text, heading, image]);
    const sectionText = DOMPurify.sanitize(content?.text);
    
    const handleSectionImageClick = () => {
      handleSectionEditChange(["section1Image"]);
    };


    return (
      <div className="first-content-box-main-container  ">
        <div
          onClick={() => handleSectionEditChange(["salesTextHeadline1", "salesText1"]) }
          style={{ cursor: "pointer" }}
          className="first-content-box-left-container   !bg-white   "
        >
          <div
            onClick={() => handleSectionEditChange(["salesTextHeadline1", "salesText1"]) }
            style={{ cursor: "pointer" }}
            className={`first-container-left-box !justify-start text-center
               overflow-hidden ${salesTextHeadline1 ? "outline-border-selected" : "outline-border"
            }`}
            id="salesTextHeadline1"
          >
            {content?.heading ? <p className="pro-temp-section-head">{content?.heading}</p> : ""}
            {content?.text ? (
              <div style={{color:'white'}}  className="edit-formatted-text ">
                 
                <div className="  pro-template-detail-text  " dangerouslySetInnerHTML={{ __html: sectionText }}></div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          onClick={handleSectionImageClick}
          style={{ cursor: "pointer",backgroundImage: `url(${image})`,backgroundSize: 'contain',backgroundRepeat: 'no-repeat',backgroundPosition: 'center',}}
          className={`first-content-box-right-container  ${
            section1Image ? "outline-border-selected" : "outline-border"
          }`}
        >
          {image && (
           
            <div onMouseEnter={() => { setIsHovered(true);  }}
              onMouseLeave={() => { setIsHovered(false); }}
              style={{ position: 'relative', display: 'inline-block', cursor: 'pointer',margin:0 ,width:"100%", height:"600px"}}>
              
              {isHovered && (
                <div
                  className="background-gallery-image-overlay-edit-image-section    !rounded-none"
                  style={{position: 'absolute'}}
                >
                  <div className="edit-image-upper-portion !items-center !justify-between"></div>
                  <div className="edit-image-lower-portion">
                    <button
                      className="gallery-image-edit-button bg-white !w-auto !py-0 !pl-0 !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7]"
                      onClick={handleSectionImageClick}
                    >
                      <img className="m-[8px]" width="125px" src={canvaIcon} alt="Sync Canva edits" />
                      <p className="text-[13px] font-semibold">Edit in Canva</p>
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

export default ProTemplateFirstContentBox