import { useState, useEffect } from "react";

function useDeliveryDate() {
  const [dates, setDates] = useState({ fromDate: "", toDate: "" });

  useEffect(() => {
    // Helper function to check if a day is a weekend
    const isWeekend = (date) => {
      const day = date.getDay();
      return day === 6 || day === 0; // Saturday (6) or Sunday (0)
    };

    // Helper function to get the next Monday
    const nextMonday = (date) => {
      const day = date.getDay();
      const offset = day === 0 ? 1 : 8 - day; // Offset to next Monday
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + offset);
      return newDate;
    };

    // Calculate "from" date
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() + 9); // Add 9 days to today
    if (isWeekend(fromDate)) {
      fromDate = nextMonday(fromDate); // Skip to next Monday
    }

    // Calculate "to" date
    let toDate = new Date();
    toDate.setDate(toDate.getDate() + 13); // Add 13 days to today
    if (isWeekend(toDate)) {
      toDate = nextMonday(toDate); // Skip to next Monday
    }

    // Format dates as "dddd MMMM dS"
    const formatDate = (date) => {
      const options = { weekday: "long", month: "long", day: "numeric" };
      const day = date.getDate();
      const suffix =
        day > 3 && day < 21
          ? "th"
          : ["st", "nd", "rd"][(day % 10) - 1] || "th"; // Add ordinal suffix
      return date.toLocaleDateString("en-US", options).replace(day, `${day}${suffix}`);
    };

    // Set formatted dates in state
    setDates({
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate),
    });
  }, []);

  return dates;
}

export default useDeliveryDate;
