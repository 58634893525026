import React from 'react'

const ProTemplateTableHeadingEditComponent = ({ tableData, setTableData }) => {
    return (
        <>
            <div className="title-container">Table Heading</div>
            <div className="input-main-container">
                <div className="input-container">
                    <label className="input-label">{tableData.heading}</label>
                    <input
                        className="form-input"
                        type="text"
                        value={tableData.heading}
                        onChange={(e) =>
                            setTableData((prev) => ({
                                ...prev, heading: e.target.value

                            }))
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default ProTemplateTableHeadingEditComponent