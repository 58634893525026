import ProTemplateImageEdit from "./ProTemplateImageEdit";

const ProTemplateTextOnImageEditComponent = ({
  setReviews,
  setTestimonials,
  setSectionsImages,

  textOnImageDetails,
  setTextOnImageDetails,
   isCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
}) => {
  return (
    <>
      <div className="title-container">Image Content</div>

      <div className="input-container">
        <label className="input-label">Edit Content</label>
        <ProTemplateImageEdit
        {...{setReviews,
          setTestimonials,
          setTextOnImageDetails,
          setSectionsImages,}}
          
          image={textOnImageDetails.img}
          setProductImages={setTextOnImageDetails}
          isCanvaConnected={isCanvaConnected}
          awaitingRefresh={awaitingRefresh}
          setAwaitingRefresh={setAwaitingRefresh}
          isEditCanvaLoading={isEditCanvaLoading}
          setIsEditCanvaLoading={setIsEditCanvaLoading}
          imageIndex={0}
          toWhich={'textOnImage'} />
        <input
          className="form-input"
          type="text"
          value={textOnImageDetails.title}
          onChange={(e) =>
            setTextOnImageDetails((prev) => ({
              ...prev,
              title: e.target.value,
            }))
          }
        />
        <textarea
          rows="6"
          className="form-input"
          value={textOnImageDetails.details}
          onChange={(e) =>
            setTextOnImageDetails((prev) => ({
              ...prev,
              details: e.target.value,
            }))
          }
        />
      </div>
    </>
  );
};
export default ProTemplateTextOnImageEditComponent;
