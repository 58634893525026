import { configureStore } from "@reduxjs/toolkit";
import productVariantsSlice from "../slices/product-variants-slice";
import shippingConfigSlice from "../slices/shipping-config-slice";
import publishProductSlice from "../slices/publish-product-slice";
import replaceVariantSlice from "../slices/replace-variant-slice";
import authUserSlice from "../slices/auth-user-slice";
import staticMediaImagesSlice from "../slices/static-media-images-slice";
import templateSlice from "../slices/template-slice";

const store = configureStore({
  reducer: {
    productVariants: productVariantsSlice,
    shippingConfiguration: shippingConfigSlice,
    publishProduct: publishProductSlice,
    replaceVariant: replaceVariantSlice,
    authUser:authUserSlice,
    staticMediaImagesSlice:staticMediaImagesSlice,
    templateSlice:templateSlice,


  }
})
export default store