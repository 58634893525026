const ProTemplateCompanyNameEditComponent = ({
  companyName,
  setCompanyName,
}) => {
  return (
    <>
      <div className="title-container">Company </div>
      <div className="input-main-container">
        <div className="input-container">
          <label className="input-label">Company Name</label>
          <input
            className="form-input"
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};
export default ProTemplateCompanyNameEditComponent;
