import checkDisableVariantOrProperty from "../../Utilities/productVariantsHandlers/checkDisableVariantOrProperty";
import getPriceOfProperty from "../../Utilities/productVariantsHandlers/getPriceOfProperty";
import getPriceOfUniquProperties from "../../Utilities/productVariantsHandlers/getPriceOfUniquProperties";
import makeVariants from "../../Utilities/productVariantsHandlers/makeVariants";
import { v4 as uuidv4 } from "uuid";
import varinatsShippedFrom from "../../Utilities/productVariantsHandlers/VariantsShippedFrom";
import getAliExpressPrices from "../../Utilities/productVariantsHandlers/getAliExpressPrices";

const useMakeTableData = ({
  productData,
  uniqueValuesObject,
  groupBy,
  propertiesArray,
  globallyDisabledProperties,
  shipsFrom,
}) => {
  const { data: prodData } = productData;
  const { product_variants: variants } = prodData;
  const filtered = varinatsShippedFrom(variants, shipsFrom);
  const product_variants = filtered.length > 0 ? filtered : variants;
  // get ali express prices
  const aliexpressPricesObj = getAliExpressPrices(productData);

  const generteDynamicKeyObject = (property, parentOrChild, childObject) => {
    return propertiesArray?.map((p, i) => {
      return parentOrChild === "parent"
        ? {
            [p]:
              p === groupBy
                ? property
                : uniqueValuesObject[propertiesArray[i]]
                    ?.map((prop, i) => prop)
                    .join(", "),
          }
        : {
            [p]: p === groupBy ? property : childObject[p],
          };
    });
  };

  const tableData = uniqueValuesObject[groupBy]?.map((property, i) => {
    const dynamicKeys = generteDynamicKeyObject(property, "parent");
    const newObject = dynamicKeys.reduce(
      (acc, obj) => ({ ...acc, ...obj }),
      {}
    );
    const variants = makeVariants(
      propertiesArray,
      uniqueValuesObject,
      groupBy,
      property
    );
    const variant = getPriceOfProperty(
      groupBy,
      newObject[groupBy],
      product_variants,
      shipsFrom
    );

    // console.log(" %c ----------------    PRODUCT RECORD Variants ----------------", 'color: yellow; font-weight:bold');
    //         console.log(variants)
    //         console.log(variant)
    // console.log(" %c ----------------    PRODUCT RECORD varinats ----------------", 'color: yellow; font-weight:bold');
    // const disable = checkVariantDisable(product_variants, groupBy, property)
    const disable = checkDisableVariantOrProperty(
      product_variants,
      newObject,
      groupBy,
      property,
      globallyDisabledProperties,
      shipsFrom
    );
    // calculate profit
    const aliExpressPrice = aliexpressPricesObj[variant.aliexpress_sku]?.price;
    const profit =
      variant.enableSalePrice && variant.discounted_price > 0
        ? variant.discounted_price - aliExpressPrice
        : variant.price > 0
        ? variant.price - aliExpressPrice
        : 0;

    return {
      key: i,
      ...newObject,
      Price: variant.price,
      SalePrice: variant.discounted_price,
      status: disable ? "disabled" : "active",
      enableSalePrice: variant.enableSalePrice,
      SKU: variant?.shopify_sku || "",
      grossProfit: profit.toFixed(2),
      parent: true,
      // variants
      children:
        variants.length > 1 &&
        variants.map((variantData, j) => {
          const dynamicKeys = generteDynamicKeyObject(
            property,
            "child",
            variantData
          );
          const newObject = dynamicKeys.reduce(
            (acc, obj) => ({ ...acc, ...obj }),
            {}
          );
          const variant = getPriceOfUniquProperties(
            newObject,
            productData,
            product_variants,
            shipsFrom
          );
          const uniqueKey = uuidv4();
          console.log("new objecttttt:", newObject);

          const disable = checkDisableVariantOrProperty(
            product_variants,
            newObject,
            groupBy,
            property,
            globallyDisabledProperties,
            shipsFrom
          );
          // calculate gross profit
          const aliExpressPrice =
            aliexpressPricesObj[variant.aliexpress_sku]?.price;
          const profit =
            variant.enableSalePrice && variant.discounted_price > 0
              ? variant.discounted_price - aliExpressPrice
              : variant.price > 0
              ? variant.price - aliExpressPrice
              : 0;

          return {
            parent: false,
            Price: variant.price,
            SalePrice: variant.discounted_price,
            status: disable ? "disabled" : "active",
            SKU: variant?.shopify_sku || "",
            status: disable
              ? "disabled"
              : variant.price === undefined
              ? "disabled"
              : "active",
            enableSalePrice: variant.enableSalePrice,
            grossProfit: profit.toFixed(2),

            key: uniqueKey,
            ...newObject,
          };
        }),
    };
  });
  return tableData;
};
export default useMakeTableData;
