import React from 'react'
import { ArrowRightOutlined } from "@ant-design/icons";

const ErrorBanner = () => {
    const refresh = () => {
        window.location.href = "/";
      };
    
      return (
        <div
          className={`upgrade-banner rounded p-10 flex justify-between items-center w-full mt-4 bg-[#FEF3F2] error`}
        >
          <div className="flex flex-col justify-between gap-2">
            <span
              className={`text-xl font-semibold text-[#B42318]`}
            >
             Wait loading import history!
            </span>
            <span
              className={`text-lg font-normal text-[#C64E41]`}
            >
             Please wait while we get the import history if the issue persist please try reloading!
            </span>
          </div>
          <button className="upgrade-plan-btn" type="button" onClick={refresh}>
          Reload
            <ArrowRightOutlined
              style={{
                fontSize: "12px",
                color:   "#B42318"  ,
                strokeWidth: "40",
                stroke:  "#B42318"  ,
              }}
            />
          </button>
        </div>
      );
}

export default ErrorBanner