import React, { useState } from "react";
import editpencil from "../../../../assets/editpencil.svg";
import cameraIcon from "../../../../assets/cameraIcon.svg";

import { callBackendAPI } from "../../../../helpers/apiCalls";
import { Spin } from "antd";
import LazyBackgroundImg from "../../../LazyBackgroundImg";
import canvaIcon from "../../../../assets/canva.svg";
import ProTemplateImageEditModal from "../ImagesGalleryModal/ProTemplateImageEditModal";
import PetsKidsEditImageModal from "./PetsKidsEditImageModal";
import { setToUpdateImageDetails } from "../../../../redux/slices/product-variants-slice";
import { useDispatch } from "react-redux";

const PetsKidsMainTestimonialImageEdit = ({
  image,
  setProductImages,
  isCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  imageIndex,
  toWhich,
}) => {
  console.log(" images array", image);
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);
  const [multipleFlag, setMultipleFlag] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const dispatch = useDispatch();

  const handleImageEdit = () => {
    if (isEditCanvaLoading) return;
    setIsImagesModalVisible(true);
  };

  const handleCanva = (imageUrl) => {
    if (isEditCanvaLoading) return;
    else if (awaitingRefresh) {
      setIsEditCanvaLoading(true);

      callBackendAPI("canva/design", "GET", {
        designId: awaitingRefresh.designId,
        update: true,
      })
        .then((response) => {
          if (response.status === "success") {
            if (response.accessToken) {
              localStorage.setItem("canva_access_token", response.accessToken);
            }
            console.log(" <= Canva return success in product gallery => ");
            console.log("canva/design", response);
            console.log("awaitingRefresh", awaitingRefresh);

            setProductImages((prev) => {
              const previousData = { ...prev };
              previousData[toWhich] = response?.url;
              return previousData;
            });
          }
          setAwaitingRefresh(false);
        })
        .finally(() => {
          setIsEditCanvaLoading(false);
        });
      return;
    }

    if (isCanvaConnected) {
      setIsEditCanvaLoading(true);

      const image = new Image();

      image.onload = function () {
        callBackendAPI("canva/edit", "GET", {
          imageUrl,
          width: image.width,
          height: image.height,
        })
          .then((response) => {
            console.log("product images resopnse=>", response);
            if (response.status === "success") {
              dispatch(
                setToUpdateImageDetails({
                  index: 0,
                  to: "sectionImages",
                  key: "",
                  imageUrl,
                })
              );
              if (response.accessToken) {
                localStorage.setItem(
                  "canva_access_token",
                  response.accessToken
                );
              }
              setAwaitingRefresh({
                designId: response.designId,
                imageUrl,
              });

              console.log(
                " Gallery images correlation state:",
                imageUrl.match(
                  /(?:alicdn\.com|sofia-app\.io).*\/(.*?)\./
                )?.[1] ||
                  imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*)$/)?.[1]
              );

              window.open(
                `${response.editUrl}&correlation_state=${
                  imageUrl.match(
                    /(?:alicdn\.com|sofia-app\.io).*\/(.*?)\./
                  )?.[1] ||
                  imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*)$/)?.[1]
                }`,
                "_blank"
              );
            } else {
              alert("Please reconnect to Canva");
            }
          })
          .catch((error) => {
            console.log(error);
            alert("Please reconnect to Canva");
          })
          .finally(() => {
            setIsEditCanvaLoading(false);
          });
      };

      image.src = imageUrl;
    } else {
      alert("Please connect to Canva first");
    }
  };

  const handleMouseEnter = () => {
    setHoveredIndex(true);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(false);
  };

  return (
    <>
      {!image ? (
        <div
          onClick={() =>
            isEditCanvaLoading ? null : setIsImagesModalVisible(true)
          }
          className="add-image-gallery-container"
        >
          <img src={cameraIcon} alt="Camera" />
          <p>Upload image</p>
        </div>
      ) : (
        <>
          <div className="gallery-edit-images-main-box !grid-cols-1">
            <div
              className={`${
                toWhich === "reviews" ? "h-[100px] w-[100px]" : "h-[200px]"
              }  above-spinner`}
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={handleMouseLeave}
            >
              <Spin spinning={isEditCanvaLoading}>
                <LazyBackgroundImg
                  cached={image ? true : false}
                  img={image}
                  className={`${
                    isEditCanvaLoading &&
                    "flex justify-center items-center opacity-30"
                  } !h-full !w-full gallery-image-edit-container  `}
                >
                  {hoveredIndex && (
                    <div className="background-gallery-image-overlay-edit-image-section">
                      <div className="edit-image-upper-portion !items-center !justify-between">
                        <button
                          className="gallery-image-edit-button"
                          onClick={() => handleImageEdit()}
                        >
                          <img src={editpencil} alt="edit" />
                        </button>
                      </div>
                      <div className="edit-image-lower-portion">
                        {
                          <button
                            className="gallery-image-edit-button bg-white !w-auto  !py-0 !pl-0 !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7] "
                            onClick={() => {
                              handleCanva(image);
                            }}
                          >
                            <img
                              className="m-[8px]"
                              width="125px"
                              src={canvaIcon}
                              alt="Sync Canva edits"
                            />
                            {awaitingRefresh ? (
                              <p className="text-[11px] font-semibold">
                                Sync Canva edits
                              </p>
                            ) : (
                              <p className="text-[13px] font-semibold">
                                Edit in Canva
                              </p>
                            )}
                          </button>
                        }
                      </div>
                    </div>
                  )}
                </LazyBackgroundImg>
              </Spin>
            </div>
          </div>
        </>
      )}
      <PetsKidsEditImageModal
        multipleFlag={multipleFlag}
        openModal={isImagesModalVisible}
        image={image}
        setProductImages={setProductImages}
        setIsImagesModalVisible={setIsImagesModalVisible}
        imageindex={imageIndex}
        setMultipleFlag={setMultipleFlag}
        productImageFlag={true}
        toWhich={toWhich}
      />
    </>
  );
};

export default PetsKidsMainTestimonialImageEdit;
