import { useState } from "react";
import "./proTemplate.css";
import ProTemplateHeader from "./ProTemplateHeader";
import ProTemplatePriceAndHeading from "./ProTemplatePriceAndHeading";
import ProTemplateTabs from "./ProTemplateTabs";
import ProTemplateCarousel from "./ProTemplateCarousel";
import ProTemplateSectionImages from "./ProTemplateSectionImages";
import ProTemplatePrecautionDeatils from "./ProTemplatePrecautionDeatils";
import ProTemplateFeatures from "./ProTemplateFeatures";
import ProTemplateTexOnImage from "./ProTemplateTexOnImage";
import icon from "../../assets/logo.png";

import ProTemplateFooter from "./ProTemplateFooter";
import ProTemplateCollapsContainer from "./ProTemplateCollapseContainer";

const details = `Lorem Ipsum is simply dummy text of the printing and 
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
 when an unknown printer took a galley of type and scrambled it to make a type specimen book`;
const img = "https://miro.medium.com/v2/resize:fit:2400/0*hDAyhnOx767w5qma.jpg";
const imgSmall =
  "https://qtlbfsuc9dj5tkh0-61932142812.shopifypreview.com/cdn/shop/files/Crags_MetalTin_200x_a5a11298-cece-443e-9cf3-304041af7eab.png?v=1730283854&width=100";
const ProTemplate = () => {
  
  const [price, setSetPrice] = useState({
    price: 100,
    salePrice: 50,
    isSale: true,
  });
  const [tabsContent, setTabsContent] = useState([
    "First tabs Content",
    "second tabs Content",
    "third tabs Content",
  ]);
  const [testimonials, setTestimonials] = useState([
    {
      img: img,
      name: "testing2",
      details: details,
    },
    {
      img: img,
      name: "testing3",
      details: details,
    },
    {
      img: img,
      name: "testing3",
      details: details,
    },
  ]);
  const [sectionData, setSectionData] = useState([
    {
      img: img,
      heading: "image with text",
      details: details,
    },
    {
      img: img,
      heading: "image with text",
      details: details,
    },
    {
      img: img,
      heading: "image with text",
      details: details,
    },
  ]);
  const [noteDetails, setNoteDetails] = useState({
    detail: `"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
 type and scrambled it to make a type specimen book."`,
    images: [
      imgSmall,
      "https://qtlbfsuc9dj5tkh0-61932142812.shopifypreview.com/cdn/shop/files/Crags_NotSticky_200x_15ee609d-96fe-4c58-a418-6bebf4252dc6.png?v=1730283852&width=100",
      "https://qtlbfsuc9dj5tkh0-61932142812.shopifypreview.com/cdn/shop/files/2_f1289625-7de4-4691-a811-ba74d26ddc13.png?v=1730187096&width=100",
    ],
  });
  const [textOnImageDetails, setTextOnImageDetails] = useState({
    title: "Title",
    detail: "Details will be here ",
    img: img,
  });
  const [FAQData, setFAQData] = useState([
    {
      title: "Title",
      detail: "Details will be here ",
    },
    {
      title: "Title",
      detail: "Details will be here ",
    },
    {
      title: "Title",
      detail: "Details will be here ",
    },
  ]);
  const [features, setFeatures] = useState([
    {
      title: "Fast Featue",
      text: `Lorem Ipsum is simply dummy text of the printing 
      and typesetting .`,
    },
    {
      title: "Smooth Featue",
      text: `Lorem Ipsum is simply dummy text of the printing 
      and typesetting .`,
    },
    {
      title: "Fast Featue3",
      text: `Lorem Ipsum is simply dummy text of the printing 
      and typesetting .`,
    },
    {
      title: "Fast Featue4",
      text: `Lorem Ipsum is simply dummy text of the printing 
      and typesetting .`,
    },
  ]);

  return (
    <div className="bg-white">
      {/* <ProTemplateHeader /> */}
      <div className="px-[160px]">
        <ProTemplatePriceAndHeading
          price={price.price}
          salePrice={price.salePrice}
          isSale={price.isSale}
          tabsContent={tabsContent}
        />

        <ProTemplateCarousel testimonials={testimonials} />
        <ProTemplateSectionImages sectionData={sectionData} />
      </div>
      <ProTemplatePrecautionDeatils noteDetails={noteDetails} />
      <ProTemplateFeatures features={features} />
      <ProTemplateTexOnImage textOnImageDetails={textOnImageDetails} />

      <ProTemplateCollapsContainer FAQData={FAQData} />
      {/* footer */}
      {/* <ProTemplateFooter /> */}
    </div>
  );
};
export default ProTemplate;
