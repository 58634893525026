import React, { useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { message, Switch } from "antd";

const ProTemplateAddRowEditComponent = ({ tableData, setTableData }) => {
    const numberOfRowsAllowed = 10;
    const [row, setRow] = useState(["", false, false]);
    const addRowHandler = () => {
      if (tableData?.tableRows?.length === numberOfRowsAllowed) {
        setRow(["", false, false]);
        return message.info(
          `Can not enter more than ${numberOfRowsAllowed} rows!`
        );
      }
      setTableData((prevData) => {
        const updatedRows = [...prevData.tableRows];
  
        updatedRows.push(row);
        return {
          ...prevData,
          tableRows: updatedRows,
        };
      });
      setRow(["", false, false]);
    };
    return (
      <>
        <div className="title-container">
          Add Row
          {/* <img width="30px" src={trashbin} alt="delete" /> */}
        </div>
  
        <div className="input-main-container">
          <div className="input-container">
            <div className="flex justify-between items-center">
              <label className="input-label">{`Feature Details`}</label>{" "}
            </div>
  
            <input
              className={`form-input  disabled:cursor-not-allowed`}
              type="text"
              placeholder={`${
                tableData.tableRows.length >= numberOfRowsAllowed
                  ? `Can not enter more than ${numberOfRowsAllowed} rows!`
                  : ""
              }`}
              disabled={tableData.tableRows.length >= numberOfRowsAllowed}
              value={row[0]}
              onChange={(e) =>
                setRow((prevData) => {
                  const updatedData = [...prevData];
                  updatedData[0] = e.target.value;
                  return updatedData;
                })
              }
            />
          </div>
          <div className="input-container">
            <label className="input-label">{tableData.tableProperties[0]}</label>
            <Switch
              className="pro-temp-table-switch-styles "
              checked={row[1]}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              disabled={tableData.tableRows.length >= numberOfRowsAllowed}
              onChange={(e) =>
                setRow((prevData) => {
                  const updatedData = [...prevData];
                  updatedData[1] = !updatedData[1];
                  return updatedData;
                })
              }
            />
          </div>
          <div className="input-container">
            <label className="input-label">{tableData.tableProperties[1]}</label>
            <Switch
              className=" pro-temp-table-switch-styles "
              checked={row[2]}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              disabled={tableData.tableRows.length >= numberOfRowsAllowed}
              onChange={(e) =>
                setRow((prevData) => {
                  const updatedData = [...prevData];
                  updatedData[2] = !updatedData[2];
                  return updatedData;
                })
              }
            />
          </div>
        </div>
        <div className="w-full my-5 px-4">
          <button
            onClick={addRowHandler}
            type="button"
            className="top-container-right-btns-container-button w-full disabled:!cursor-not-allowed"
            disabled={row[0] === ""}
          >
            Add Row
          </button>
        </div>
      </>
    );
  };
  export default ProTemplateAddRowEditComponent