import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
// import modaldownloadicon from "../../../assets/modaldownloadicon.svg";
import { callBackendAPI } from "../../helpers/apiCalls";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import currentAuthenticatedUser from "../../authUser";
import { URLS } from "../../URLS";
import CustomLoader from "../CustomLoader";

const AllowPermisionModal = ({ OpenModal, setOpModal }) => {
  const { open, scopes } = OpenModal;
  const navigate = useNavigate();
  const { shopifyShopDetails } = useSelector((store) => store.authUser);
  const { name: shopName } = shopifyShopDetails || {};
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const modal = document.querySelector(".ant-modal-content");
    if (modal) {
      modal.setAttribute("style", "padding: 20px !important;");
    }
  }, [OpenModal]);

  const handleCancel = () => {
    setOpModal({ open: false, scopes: `` });
    navigate("/");
  };
  const handleConfirm = async () => {};

  const handleRedirect = async () => {
    setLoading(true);
    const { userId } = await currentAuthenticatedUser();
    try {
      const response = await callBackendAPI(
        `store/add_scopes/shopify?shop=${encodeURIComponent(
          shopName
        )}&userId=${encodeURIComponent(userId)}&scopes=${encodeURIComponent(
          scopes
        )}`,
        "GET",
        {}
      );
      if (response.url) {
        window.location.href = response.url;
      }
    } catch (error) {
      console.error("redirect error", error);
    } finally {
      setLoading(false);
    }
   
  };

  return (
    <div>
      <Modal
        closable={false}
        open={OpenModal}
        onCancel={handleCancel}
        footer={
          <div className="px-3 flex w-full !justify-end top-container-right-btns-container">
            <button
              onClick={handleCancel}
              type="button"
              className="top-bar-main-container-white-button"
            >
              Cancel
            </button>
            <button
              onClick={handleRedirect}
              type="button"
              className="top-container-right-btns-container-button"
            >
              {
                loading? (
                  <CustomLoader width='20px' />
                ) : (
                  "Update permissions"
                )
              }
            </button>
          </div>
        }
      >
        <div className="delete-product-modal-main-container">
          <span className=" border border-[#6941C6] rounded-md p-2 !w-[80px] !h-[80px]">
            {/* <img className="w-[50px]" src={trashbin} alt="trashbin" /> */}
            <svg
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
              fill="#6941C6"
              stroke="#6941C6"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                <defs>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".a{fill:none;stroke:#6941C6;stroke-linecap:round;stroke-linejoin:round;}.b{fill:#6941C6;}",
                    }}
                  />
                </defs>
                <path
                  className="a"
                  d="M34.3371,28.5763l-2.3188,4.0048a.7485.7485,0,0,1-.9336.3273l-3.8164-1.5323q-.2306.1762-.47.34l-4.0788-4.23a5.7129,5.7129,0,1,0-8.04-4.1856L8.8544,21.62q.0264-.4.0786-.7984L5.7066,18.3a.7418.7418,0,0,1-.1867-.9744L8.582,12.0368a.7485.7485,0,0,1,.9336-.3273l3.8313,1.5323a11.4874,11.4874,0,0,1,2.5841-1.4876l.5751-4.039a.7459.7459,0,0,1,.7469-.64h6.1391a.7459.7459,0,0,1,.7469.64l.5751,4.039a11.7228,11.7228,0,0,1,2.5841,1.4876L31.1146,11.71a.7485.7485,0,0,1,.9336.3273l.7062,1.22"
                />
                <path
                  className="a"
                  d="M21.8069,30.8683a5.7129,5.7129,0,0,1-8.04-4.1856h0l-5.825-1.6809q-.0226.3441-.026.6892A12.235,12.235,0,0,0,8.02,27.1787L4.7936,29.7a.7419.7419,0,0,0-.1868.9744L7.669,35.9632a.7482.7482,0,0,0,.9335.3272l3.8164-1.5322a11.7252,11.7252,0,0,0,2.5842,1.4876l.5751,4.0389a.7457.7457,0,0,0,.7468.64h6.1242a.7459.7459,0,0,0,.7469-.64l.5751-4.0389a11.484,11.484,0,0,0,2.114-1.148Z"
                />
                <path
                  className="a"
                  d="M25.4091,28.4979H43.5L34.4546,10.4071Z"
                />
                <circle className="b" cx="34.4546" cy="25.6415" r="0.75" />
                <path className="a" d="M34.4546,22.785V16.12" />
              </g>
            </svg>
          </span>
          <div className="delete-prduct-modal-content">
            <h4>Access Denied!</h4>
            <p>Save as draft and update permissions</p>
          </div>
        </div>
      </Modal>
    </div>
  );
  //
};

export default AllowPermisionModal;
