import React from 'react'

const PetsKidsFeatureUnderPNEditComponent = ({
  productData, handleInputChange
}) => {
  return (
    <div>
      <div className="title-container">Features</div>
      <div className="input-main-container">
        {
          productData?.bulletBenefits.map((feature, index) => {

            return (
              <div key={index} className="input-container">
                <label className="input-label">Feature {index + 1}</label>
                <input
                  className="form-input"
                  type="text"
                  value={feature}
                  onChange={(e) =>
                    handleInputChange(`bulletBenefits`, e.target.value, index)
                  }
                />
              </div>
            )
          })
        }
       
      </div>

    </div>
  )
}

export default PetsKidsFeatureUnderPNEditComponent