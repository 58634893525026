import { Input, Select, Switch, Table, Alert } from "antd";
import "./VariantPricingTable.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import eye from "../../assets/eye.svg";
import eyeOff from "../../assets/eye-off.svg";
import useVariantsData from "../hooks/useVariantsData";
import checkPrentRowAndKeys from "../../Utilities/productVariantsHandlers/checkParentRowAndKeys";
import checkPriceDifference from "../../Utilities/productVariantsHandlers/checkPriceDifference";
import checkDisableVariantOrProperty from "../../Utilities/productVariantsHandlers/checkDisableVariantOrProperty";
import findIfDisable from "../../Utilities/productVariantsHandlers/findIfDisable";
import {
  changeLabelNameOfProperties,
  changeLabelOfProperties,
  changePriceAndDiscountOfSpecific,
  changePriceAndDiscountOfVariants,
  changeSKU,
  disableSpecificProperties,
  disableVariant,
  enableDiscountOfVariants,
  recalculateTheEnabledVariants,
  setPriceOfAllVariants,
} from "../../redux/slices/product-variants-slice";
import useMakeTableData from "../hooks/useMakeTableData";
import varinatsShippedFrom from "../../Utilities/productVariantsHandlers/VariantsShippedFrom";
import checKParentSaleIfEnabledInAnyChild from "../../Utilities/productVariantsHandlers/checKParentSaleIfEnabledInAnyChild";
import LazyBackgroundImg from "../LazyBackgroundImg";

const { Option } = Select;

const VariantPricingTable = ({
  shipsFrom,
  setShipsFrom,
  groupBy,
  setGroupBy,
  imageCache,
}) => {
  const dispatch = useDispatch();
  const {
    productData,
    totalEnabledVariants,
    totalAllowedVariants,
    totalVriants,
    specificShipsFromVariants,
  } = useSelector((store) => store.productVariants);
  const { propertiesArray, shipsFromArray } = useVariantsData(productData);

  useEffect(() => {
    const selectIcon = `
       <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m4.45 7.43 4.375 4.375L13.2 7.43" stroke="#667085" stroke-width="1.458" stroke-linecap="round" stroke-linejoin="round"/>
      </svg> `;
    document.querySelectorAll(".ant-select-suffix").forEach((svg) => {
      svg.outerHTML = selectIcon;
    });
    dispatch(recalculateTheEnabledVariants({ shipsFrom: shipsFrom }));
  }, []);

  const handleGroupByChange = (value) => {
    setGroupBy(value);
  };
  const handleShipsFromChange = (value) => {
    setGroupBy(0);
    setShipsFrom(value);
    dispatch(recalculateTheEnabledVariants({ shipsFrom: value }));
  };

  return (
    <div className="variant-pricing-table-main-container">
      {
        totalEnabledVariants > totalAllowedVariants ? (
          <Alert
            message={`Enable Variants (${totalEnabledVariants}) 
            exceed the allowed limit (${totalAllowedVariants})`}
            type="error"
            className={"variants-count-error-message-detail-page"}
          />
        ) : (
          ""
        )
        // <Alert message={`{${specificShipsFromVariants.shipsFrom} has (${specificShipsFromVariants.total}) variants } and   Enabled Variants (${totalEnabledVariants}) Total Including shipsfrom (${totalVriants}) `} type="success" className={"variants-count-error-message mb-4"} />
      }

      {shipsFromArray.length > 0 && (
        <Select
          placeholder="Select Ships From"
          value={shipsFrom}
          className="pagination-form-control    !w-[200px]  !h-[42px]"
          onChange={(e) => handleShipsFromChange(e)}
          style={{ marginBottom: "14px" }}
          optionLabelProp="label"
        >
          {shipsFromArray?.map((col, i) => (
            <Option key={i + 1} value={col} label={`Ships from ${col} `}>
              <span className="flex gap-2 w-full items-center">
                <p>{col}</p>
              </span>
            </Option>
          ))}
        </Select>
      )}
      {!shipsFrom && shipsFromArray.length > 0 ? null : (
        <>
          <h6 className="variant-pricing-heading">Variant prices</h6>
          <p className="variant-pricing-para">
            Set up price for each variant and hide individual variants you don’t
            want to sell
          </p>
          <div className="w-full flex items-center gap-4">
            <Select
              disabled={!shipsFrom && shipsFromArray.length > 0}
              value={groupBy}
              className="pagination-form-control !w-[200px]  !h-[42px]"
              onChange={handleGroupByChange}
              style={{ marginBottom: "14px" }}
              optionLabelProp="label"
            >
              {propertiesArray?.map((col, i) => (
                <Option key={i} value={i} label={`Group by ${col} `}>
                  <span className="flex gap-2 w-full items-center">
                    <p>{col}</p>
                  </span>
                </Option>
              ))}
            </Select>
          </div>
          <VariantsTable
            groupBy={groupBy}
            shipsFrom={shipsFrom}
            imageCache={imageCache}
          />
        </>
      )}
    </div>
  );
};
export default VariantPricingTable;

const VariantsTable = (props) => {
  const dispatch = useDispatch();
  const {
    productData,
    globallyDisabledProperties,
    variantsArrangedIndexesObject: uniqueValuesObject,
  } = useSelector((store) => store.productVariants);
  const { data: prodData } = productData;
  const { product_variants } = prodData;
  const { propertiesArray, newImagesObject, imageKey, shopifySkusObj } =
    useVariantsData(productData);
  const [focusedInputId, setFocusedInputId] = useState(null);
  const [previousSkuName, setPreviousSkuName] = useState("");
  const [shopifySkus, setShopifySkus] = useState(shopifySkusObj);
  const [shopifySkuUpdate, setShopifySkuUpdate] = useState(false);
  const [allSalePricesDisabled, setAllSalePricesDisabled] = useState(false);

  let groupBy = propertiesArray[props.groupBy];

  const tableData = useMakeTableData({
    productData,
    uniqueValuesObject,
    groupBy,
    propertiesArray,
    globallyDisabledProperties,
    shipsFrom: props.shipsFrom,
  });

  useEffect(() => {
    if (focusedInputId) {
      const inputToFocus = document.getElementById(focusedInputId);
      if (inputToFocus) {
        inputToFocus.focus();
      }
    }
  }, [focusedInputId, tableData]);

  useEffect(() => {
    const selectIconUp = `
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m4.45 7.43 4.375 4.375L13.2 7.43" stroke="#667085" stroke-width="1.458" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

    document
      .querySelectorAll(".ant-table-row-expand-icon")
      .forEach((button) => {
        button.style.content = "";
        button.style.border = "none";
        button.innerHTML = selectIconUp;
      });
    document.querySelectorAll(".ant-table-row-level-0").forEach((tr) => {
      tr.style.setProperty("background-color", "#fafafa", "important");
    });
  }, [props.groupBy]);

  const handlePriceChange = (toChange, record, value) => {
    if (isNaN(value)) return;
    value = value.replace(/^0+(?!$)/, "");

    if (value === "" || parseFloat(value) < 0) {
      value = "0";
    } else if (parseFloat(value) > 99999) {
      return;
    }
    const { keys, parent } = checkPrentRowAndKeys(record);
    if (parent) {
      dispatch(
        changePriceAndDiscountOfVariants({
          toChange,
          properties: keys,
          value,
          propertyValue: record[groupBy],
          property: groupBy,
          shipsFrom: props.shipsFrom,
        })
      );
    } else {
      dispatch(
        changePriceAndDiscountOfSpecific({
          toChange,
          properties: { ...keys },
          value,
          shipsFrom: props.shipsFrom,
        })
      );
    }
  };

  const handleDisable = (record, is_disabled) => {
    const { keys } = checkPrentRowAndKeys(record);
    let parent = record.parent;
    console.log("parentparentparent", record.parent);

    // console.log("parentparentparent",parent,keys,"disable props",{
    //   value: keys[groupBy],
    //   property: groupBy,
    //   is_disabled: is_disabled,
    //   shipsFrom: props.shipsFrom,
    // },"globally disable:",globallyDisabledProperties);

    if (parent) {
      dispatch(
        disableVariant({
          value: keys[groupBy],
          property: groupBy,
          is_disabled: is_disabled,
          shipsFrom: props.shipsFrom,
        })
      );
    } else {
      dispatch(
        disableSpecificProperties({
          properties: keys,
          isDisabled: is_disabled,
          shipsFrom: props.shipsFrom,
        })
      );
    }
  };
  // local handling the label names
  // Handle label change for local state
  const handleLabelChange = (property, value, index) => {
    if (value !== property && localLabels.hasOwnProperty(value)) {
      setDuplicateLabelNameError({ error: true, index: index });
      //  return
    } else {
      setDuplicateLabelNameError({ error: false, index: "" });
    }
    setLocalLabels({
      ...localLabels,
      [property]: value,
    });
    console.log("here", localLabels[property]);
  };

  // Sync local label to Redux only on blur
  const handleLabelBlur = (property) => {
    setFocusedInputId();

    const trimmedLabel = localLabels[property]?.trim();
    if (uniqueValuesObject.hasOwnProperty(trimmedLabel)) {
      setDuplicateLabelNameError({ error: false, index: "" });
      setLocalLabels((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
      return;
    } else if (trimmedLabel && trimmedLabel !== property) {
      dispatch(
        changeLabelNameOfProperties({
          property,
          value: trimmedLabel,
        })
      );
      setUpdate(!update);
      setLocalLabels((prevLabels) => ({
        ...prevLabels,
        [property]: trimmedLabel, // Reset to the default name if the input is empty
      }));
    } else if (!trimmedLabel) {
      setLocalLabels((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
    }
  };

  const [update, setUpdate] = useState(false);
  const [duplicateLabelNameError, setDuplicateLabelNameError] = useState({
    error: false,
    index: "",
  });
  const [duplicatePropertyNameError, setDuplicatePropertyNameError] = useState({
    error: false,
    index: "",
  });
  const [duplicateShopiySkuError, setDuplicateShopiySkuError] = useState({
    error: false,
    index: "",
  });
  // const [duplicateLabelNameError, setDuplicateLabelNameError] = useState({
  //   error: false,
  //   index: "",
  // });
  const [updatePropertyName, setUpdatePropertyName] = useState(false);
  // Local state to handle labels independently from Redux
  const [localLabels, setLocalLabels] = useState(
    propertiesArray.reduce((acc, property) => {
      acc[property] = property;
      return acc;
    }, {})
  );
  const [localPropertiesLabel, setLocalPropertiesLabel] = useState(
    Object.fromEntries(
      Object.values(uniqueValuesObject)
        .flat()
        .map((property) => [property, property])
    )
  );
  useEffect(() => {
    setLocalLabels(
      propertiesArray.reduce((acc, property) => {
        acc[property] = property;
        return acc;
      }, {})
    );
  }, [update]);
  useEffect(() => {
    setLocalPropertiesLabel(
      Object.fromEntries(
        Object.values(uniqueValuesObject)
          .flat()
          .map((property) => [property, property])
      )
    );
  }, [updatePropertyName]);
  useEffect(() => {
    setShopifySkus(shopifySkusObj);
  }, [shopifySkuUpdate]);
  //local  properties states
  const handlePropertyLabelChange = (property, value, index) => {
    if (value !== property && localPropertiesLabel.hasOwnProperty(value)) {
      setDuplicatePropertyNameError({ error: true, index: index });
      //  return
    } else {
      setDuplicatePropertyNameError({ error: false, index: "" });
    }
    setLocalPropertiesLabel({
      ...localPropertiesLabel,
      [property]: value,
    });
    console.log("here", localPropertiesLabel[property]);
  };

  // Sync local label to Redux only on blur
  const handlePropertyLabelBlur = (property, Mainproperty, newValue) => {
    setFocusedInputId();
    const trimmedLabel = localPropertiesLabel[property]?.trim();
    if (localPropertiesLabel.hasOwnProperty(trimmedLabel)) {
      setDuplicatePropertyNameError({ error: false, index: "" });
      setLocalPropertiesLabel((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
      return;
    } else if (trimmedLabel && trimmedLabel !== property) {
      dispatch(
        changeLabelOfProperties({
          property: Mainproperty,
          propertyValue: property,
          value: newValue,
        })
      );
      setUpdatePropertyName((prev) => !prev);
      setLocalPropertiesLabel((prevLabels) => ({
        ...prevLabels,
        [property]: trimmedLabel, // Reset to the default name if the input is empty
      }));
    } else if (!trimmedLabel) {
      setLocalPropertiesLabel((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
    }
  };

  // shopify skus state management
  // local state updating
  const handleShopifySkuChange = (property, value, index) => {
    if (value !== property && shopifySkus.hasOwnProperty(value)) {
      setDuplicateShopiySkuError({ error: true, index: index });
      //  return
    } else {
      setDuplicateShopiySkuError({ error: false, index: "" });
    }
    setShopifySkus({
      ...shopifySkus,
      [property]: value,
    });
    // console.log("here", localPropertiesLabel[property]);
  };

  // Sync local label to Redux only on blur
  const handleShopifySkuBlur = ({
    property,
    properties,
    value,
    propertyValue,
    shipsFrom,
  }) => {
    setFocusedInputId();
    const trimmedLabel = shopifySkus[property]?.trim();
    if (shopifySkus.hasOwnProperty(trimmedLabel)) {
      setDuplicateShopiySkuError({ error: false, index: "" });
      setShopifySkus((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
      return;
    } else if (trimmedLabel && trimmedLabel !== property) {
      dispatch(
        changeSKU({
          properties,
          value,
          propertyValue,
          shipsFrom,
        })
      );
      setShopifySkuUpdate((prev) => !prev);
      setShopifySkus((prevLabels) => ({
        ...prevLabels,
        [property]: trimmedLabel, // Reset to the default name if the input is empty
      }));
    } else if (!trimmedLabel) {
      setShopifySkus((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "disable",
      key: "disable",
      width: "3%",
      render: (text, record, i) => {
        const property = record[groupBy];
        const disable = checkDisableVariantOrProperty(
          product_variants,
          record,
          groupBy,
          property,
          globallyDisabledProperties,
          props.shipsFrom
        );
        return disable ? (
          <img
            onClick={() => handleDisable(record, false)}
            className="pricing-table-eye-styles"
            src={eyeOff}
            alt=""
          />
        ) : (
          <img
            onClick={() => handleDisable(record, true)}
            className="pricing-table-eye-styles"
            src={eye}
            alt=""
          />
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "SKU",
      key: "SKU",
      width: "15%",
      render: (_, record, i) => {
        const { keys, parent } = checkPrentRowAndKeys(record);
        const mergedValues = Object.values(keys).join("-");
        console.log(
          "image params=>>object:",
          newImagesObject,
          "imagekey:",
          imageKey,
          record,
          ""
        );

        return parent ? null : (
          <div
            className={`sku-and-image-container ${
              record.status === "disabled" ? "inactive-row" : ""
            }`}
          >
            {newImagesObject[imageKey] ? (
              // <LazyBackgroundImg
              // img={newImagesObject[imageKey][record[imageKey]] || ''}
              // className={`sku-image-container inactive-row ${record.status === 'disabled' ? 'opacity-40':'opacity-100'}`}>

              // </LazyBackgroundImg>

              <span
                className="sku-image-container inactive-row"
                style={{
                  backgroundImage: `url(${
                    props.imageCache[
                      newImagesObject[imageKey][record[imageKey]]
                    ]?.src || ""
                  })`,
                  opacity: ` ${record.status === "disabled" ? 0.4 : 1}`,
                }}
              >
                {/* <img className="sku-img-styles" src={props.imageCache[newImagesObject[imageKey][record[imageKey]]]?.src || ''} alt="" /> */}
              </span>
            ) : null}
            <div className="flex flex-col ">
              <Input
                id={`${mergedValues}-sku-${i}`}
                value={shopifySkus[record.SKU]}
                disabled={record.status === "disabled"}
                onChange={(e) =>
                  handleShopifySkuChange(record.SKU, e.target.value, i)
                }
                onBlur={(e) =>
                  handleShopifySkuBlur({
                    property: record.SKU,
                    properties: { ...keys },
                    value: e.target.value,
                    propertyValue: record.SKU,
                    shipsFrom: props.shipsFrom,
                  })
                }
                className={`pricing-table-input-field !w-[150px] ${
                  record.status === "disabled"
                    ? "inactive-row inactive-input"
                    : ""
                } ${
                  duplicateShopiySkuError.error &&
                  duplicateShopiySkuError.index == i &&
                  "!text-[red]"
                }`}
                onFocus={() => setFocusedInputId(`${mergedValues}-sku-${i}`)}
              />

              {duplicateShopiySkuError.error &&
                duplicateShopiySkuError.index == i && (
                  <p className="text-[12px] text-[red] mb-0 ">
                    sku already exists!
                  </p>
                )}
            </div>
          </div>
        );
      },
    },
    ...propertiesArray?.map((columnName, i) => ({
      title: (
        <>
          <Input
            id={`properties-titles-parent-${i}`}
            className={` ${
              duplicateLabelNameError.error &&
              duplicateLabelNameError.index == i
                ? "!text-[red]"
                : ""
            } pricing-table-input-field w-[150px]`}
            key={i}
            value={localLabels[columnName]}
            onChange={(e) => handleLabelChange(columnName, e.target.value, i)}
            onBlur={() => handleLabelBlur(columnName)}
            // value={columnName}
            // onChange={(e) => dispatch(changeLabelNameOfProperties({ property: columnName, value: e.target.value }))}
            onFocus={() => setFocusedInputId(`properties-titles-parent-${i}`)} // Update focused input ID on focus
          />
          {duplicateLabelNameError.error &&
            duplicateLabelNameError.index == i && (
              <p className="text-[12px] text-[red] ">
                Variant type already exists!
              </p>
            )}
        </>
      ),
      dataIndex: columnName,
      key: columnName,
      render: (_, record, i) => {
        const { keys, parent } = checkPrentRowAndKeys(record);
        return groupBy === columnName && parent ? (
          <>
            <Input
              key={i}
              disabled={record.status === "disabled"}
              onChange={
                (e) =>
                  record.status === "disabled"
                    ? null
                    : handlePropertyLabelChange(
                        record[columnName],
                        e.target.value,
                        i
                      )
                // dispatch(
                //     changeLabelOfProperties({
                //       property: columnName,
                //       value: e.target.value,
                //       propertyValue: record[columnName],
                //     })
                //   )
              }
              onBlur={(e) =>
                handlePropertyLabelBlur(
                  record[columnName],
                  columnName,
                  e.target.value
                )
              }
              className={`${
                record.status === "disabled"
                  ? "inactive-row inactive-input"
                  : "pricing-table-input-field  w-[150px]"
              } ${
                duplicatePropertyNameError.error &&
                duplicatePropertyNameError.index == i &&
                "!text-[red]"
              }`}
              value={localPropertiesLabel[record[columnName]]}
              id={`${columnName}-child-${i}`}
              onFocus={() => setFocusedInputId(`${columnName}-child-${i}`)} // Update focused input ID on focus
            />
            {duplicatePropertyNameError.error &&
              duplicatePropertyNameError.index == i && (
                <p className="text-[12px] text-[red] mb-0 ">
                  property already exists!
                </p>
              )}
          </>
        ) : groupBy === columnName && !parent ? (
          <p
            className={`${record.status === "disabled" ? "inactive-row" : ""}`}
          >
            {record[columnName]}
          </p>
        ) : parent ? (
          <span className="flex">
            {uniqueValuesObject[columnName].map((val, ind) => {
              const disable = findIfDisable(
                product_variants,
                record,
                columnName,
                val,
                groupBy,
                globallyDisabledProperties
              );
              return (
                <p key={ind} className={`${disable ? "inactive-row" : ""}`}>
                  {val}
                  {uniqueValuesObject[columnName].length === ind + 1 ? "" : ","}
                </p>
              );
            })}
          </span>
        ) : (
          <p
            className={`${record.status === "disabled" ? "inactive-row" : ""}`}
          >
            {record[columnName]}
          </p>
        );
      },
    })),
    {
      title: (
        <div>
          Price
          <Input
            prefix="$"
            type="number"
            onChange={(e) => {
              if (focusedInputId) {
                setFocusedInputId(null);
              }
              dispatch(
                setPriceOfAllVariants({ to: "price", value: e.target.value })
              );
            }}
            className="pricing-table-input-field w-[150px] mt-2"
          />
        </div>
      ),
      dataIndex: "Price",
      key: "Price",
      width: "10%",
      render: (text, record, i) => {
        const { keys, parent } = checkPrentRowAndKeys(record);
        const mergedValues = Object.values(keys).join("-");
        const { difference, high, low } = checkPriceDifference("price", record);
        return (
          <span
            className={`${
              record.status === "disabled"
                ? "inactive-row inactive-input"
                : "pricing-table-input-field w-[150px]"
            }`}
          >
            <Input
              prefix="$"
              id={`${mergedValues}-price-${i}`}
              type="number"
              value={
                record.status === "disabled"
                  ? 0
                  : parent
                  ? low
                  : record.Price || 0
              }
              disabled={record.status === "disabled"}
              onChange={(e) =>
                handlePriceChange("price", record, e.target.value)
              }
              className={`${
                !parent ? "childprice-input" : "antd-override-input-props"
              }   ${record.status === "disabled" ? "inactive-row " : ""}`}
              suffix={parent && difference ? ` - ${high}` : ""}
              onFocus={() => setFocusedInputId(`${mergedValues}-price-${i}`)}
            />
          </span>
        );
      },
    },
    {
      title: (
        <div>
          Sale Price
          <div className="flex gap-2 items-center mt-2">
            <Switch
              size="small"
              checked={allSalePricesDisabled}
              onChange={(e) => {
                setAllSalePricesDisabled(e);
                dispatch(
                  setPriceOfAllVariants({ to: "enableSalePrice", value: e })
                );
              }}
            />
            <Input
              disabled={!allSalePricesDisabled}
              prefix="$"
              type="number"
              onChange={(e) => {
                if (focusedInputId) {
                  setFocusedInputId(null);
                }
                dispatch(
                  setPriceOfAllVariants({
                    to: "salePrice",
                    value: e.target.value,
                  })
                );
              }}
              className="pricing-table-input-field !w-[150px]"
            />
          </div>
        </div>
      ),
      dataIndex: "SalePrice",
      key: "SalePrice",
      width: "10%",
      render: (text, record, i) => {
        const { keys, parent } = checkPrentRowAndKeys(record);
        const { difference, high, low } = checkPriceDifference(
          "salePrice",
          record
        );
        const checkForAnyEnabled = checKParentSaleIfEnabledInAnyChild({
          parentChild: parent,
          productData,
          propertyValue: record[groupBy],
        });
        // if (parent) {

        //     console.log(" %c ----------------    PRODUCT RECORD Sale Price ----------------", 'color: red; font-weight:bold');
        //     console.log(record[groupBy], '= found =>', checkForAnyEnabled)
        //     console.log(" %c ----------------    PRODUCT RECORD Sale Price ----------------", 'color: red; font-weight:bold');
        // }
        // console.log(" %c ----------------    PRODUCT RECORD Sale Price ----------------", 'color: yellow; font-weight:bold');
        // console.log(record)
        // console.log(" %c ----------------    PRODUCT RECORD Sale Price ----------------", 'color: yellow; font-weight:bold');
        const mergedValues = Object.values(keys).join("-");
        return (
          <div className="flex items-center gap-[8px]">
            <Switch
              size="small"
              disabled={record.status === "disabled"}
              checked={
                (record.enableSalePrice && record.status !== "disabled") ||
                checkForAnyEnabled
              }
              onChange={() =>
                record.status === "disable"
                  ? null
                  : dispatch(
                      enableDiscountOfVariants({
                        parentChild: parent,
                        propertyValue: record[groupBy],
                        properties: keys,
                        onOff: record.enableSalePrice ? true : false,
                        shipsFrom: props.shipsFrom,
                      })
                    )
              }
            />

            <span
              className={`${
                record.status === "disabled"
                  ? "inactive-row inactive-input"
                  : "pricing-table-input-field  w-[150px]"
              }`}
            >
              <Input
                type="number"
                id={`${mergedValues}-salePrice-${i}`}
                disabled={
                  record.status === "disabled" || !record.enableSalePrice
                }
                value={
                  record.status === "disabled"
                    ? 0
                    : parent
                    ? low
                    : record.SalePrice || 0
                }
                onChange={(e) =>
                  handlePriceChange("salePrice", record, e.target.value)
                }
                prefix="$"
                className={`${
                  !parent ? "childprice-input" : "antd-override-input-props"
                }   ${record.status === "disabled" ? "inactive-row " : ""}`}
                suffix={parent && difference ? ` - ${high}` : ""}
                onFocus={() =>
                  setFocusedInputId(`${mergedValues}-salePrice-${i}`)
                }
              />
            </span>
          </div>
        );
      },
    },
    {
      title: <span className="justify-center flex">Gross Profit</span>,
      dataIndex: "grossProfit",
      key: "grossProfit",
      width: "150px",
      render: (text, record, i) => {
        const { keys, parent } = checkPrentRowAndKeys(record);
        const { difference, high, low } = checkPriceDifference(
          "grossProfit",
          record
        );
        console.log(
          " %c ----------------    PRODUCT RECORD Sale Price ----------------",
          "color: yellow; font-weight:bold"
        );
        console.log("=>", record, "<=");
        console.log(
          " %c ----------------    PRODUCT RECORD Sale Price ----------------",
          "color: yellow; font-weight:bold"
        );
        const mergedValues = Object.values(keys).join("-");
        return (
          <div className="flex items-center justify-center gap-[4px]">
            {record.status === "disabled" ? (
              "-"
            ) : parent && difference ? (
              <span className="flex gap-2">
                <p
                  style={{
                    color: low < 0 ? "red" : "inherit",
                    fontSize: "14px",
                  }}
                >
                  {"$" + low?.toFixed(2) + " "}
                </p>
                <p
                  style={{
                    color: high < 0 && low < 0 ? "red" : "inherit",
                    fontSize: "18px",
                  }}
                >
                  -
                </p>
                <p
                  style={{
                    color: high < 0 ? "red" : "inherit",
                    fontSize: "14px",
                  }}
                >
                  {" " + high?.toFixed(2)}
                </p>
              </span>
            ) : (
              <span
                style={{ color: record.grossProfit < 0 ? "red" : "inherit" }}
              >
                {record.grossProfit === 0 || !record.grossProfit ? (
                  <p className="!text-[#1F1F1F] font-bold">-</p>
                ) : (
                  "$ " + record.grossProfit
                )}
              </span>
            )}
          </div>
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];
  return (
    <Table
      expandable={{
        expandedRowRender: () => {
          return null;
        },
        rowExpandable: (record) =>
          record.children && record.children.length > 0,
      }}
      loading={false}
      className="variants-pricing-table"
      pagination={false}
      columns={columns}
      dataSource={tableData}
      rowClassName={(record) =>
        record.status === "disabled" ? "active-row" : "inactive-row"
      }
    />
  );
};
