import React from 'react'

const PetsKidsTemplateLastSalesSection = (
  {
    moneybackGuaranteeHeadline,
    moneybackGuaranteeText,
    moneybackGuaranteeHeadlineSection,
    moneybackGuaranteeTextSection,
    lastImageSection,
    lastSectionImage,
    handleSectionEditChange
  }
) => {
  return (
    <section className="w-full custom-type-2-section  py-4">
      <div className="w-full page-width">
        <div className="custom-type-2-container">
          <div className="custom-type-2-header text-center">
            <h2
              onClick={() => handleSectionEditChange(["moneybackGuaranteeHeadlineSection"])}
              className={`cursor-pointer custom-type-2-heading  ${moneybackGuaranteeHeadlineSection ? "border border-2 border-white"
                  : "border-dotted border-2 border-white"} `}
           >
             {moneybackGuaranteeHeadline}
            </h2>
          </div>
          <div  
          onClick={() => handleSectionEditChange(["lastSectionImage"])}
          className={`cursor-pointer custom-type-2-image-wrapper text-center p-1  ${lastImageSection ? "border border-2 border-white"
              : "border-dotted border-2 border-white"} `}
          >
            <img
              src={lastSectionImage}
              width={400} height={400} loading="lazy" />
          </div>
          <div  style={{ color: "white" }}
              onClick={() => handleSectionEditChange(["moneybackGuaranteeTextSection"])}
              className={` cursor-pointer custom-type-2-text-wrapper text-center  ${moneybackGuaranteeTextSection ? "border border-2 border-white"
                  : "border-dotted border-2 border-white"} `}
           dangerouslySetInnerHTML={{__html:moneybackGuaranteeText}}>
           </div>
        </div>
      </div>
    </section>

  )
}

export default PetsKidsTemplateLastSalesSection