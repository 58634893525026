import React, { useState } from "react";
import open from "../../assets/plus-collapse.svg";

const ProTemplateComparisonTable = ({
  tableData,
  tableLabelSection,
  tableHeadingSection,
  tableRowsSection,
  handleTableComponentsClick,
  tableRowIndex,
}) => {
  const handleAddRow = (event) => {
    event.stopPropagation();
    handleTableComponentsClick({
      keys: ["addRowSection"],
      tableComponent: "",
      index: "",
    })
    console.log('clicked');

  }
  return (
    <div className="py-[66px] px-[30px]">
      <div class="flex w-full !justify-center text-center">

      <h2 
       onClick={() =>
        handleTableComponentsClick({
          keys: ["tableHeadingSection"],
          tableComponent: "",
          index: "",
        })
      }
      className={`pro-tmp-heading ${tableHeadingSection ? "outline-border-selected" : "outline-border"}`} >
        {tableData.heading}
      </h2>
      </div>
      <table style={{ width: "100%" }}>
        <thead>
          <tr
            onClick={() =>
              handleTableComponentsClick({
                keys: ["tableLabelSection"],
                tableComponent: "tableProperties",
                index: "",
              })
            }
            className={`${tableLabelSection ? "outline-border-selected" : "outline-border"
              }`}
            style={{ borderBottom: "2px solid #ddd" }}
          >
            <th
              className="!bg-white"
              style={{ textAlign: "left", padding: "10px" }}
            >
              <button
                className="gallery-image-delete-button !bg-[#F2F2F2] !border-[black] text-[black] !w-auto p-2 gap-1"
                onClick={handleAddRow}
                danger
              >
                <img
                  width={"20px"}
                  height={"20px"}
                  src={open}
                  alt="Toggle Icon"
                />
                Add row
              </button>
            </th>
            {tableData?.tableProperties.map((property, index) => {
              return (
                <th
                  className={`${index == 0 ? "pro-temp-table-second-bg" : "!bg-white"
                    }`}
                  style={{ textAlign: "center", padding: "18px" }}
                  key={index}
                >
                  <h4 className="pro-temp-table-column-name">{property}</h4>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData?.tableRows.map((row, index) => {
            return (
              <tr
                onClick={() =>
                  handleTableComponentsClick({
                    keys: ["tableRowsSection"],
                    tableComponent: "tableRows",
                    index,
                  })
                }
                className={`cursor-pointer  ${tableRowsSection && tableRowIndex == index
                    ? "outline-border-selected"
                    : "outline-border"
                  }`}
                key={index}
              >
                <td
                  className="pro-temp-table-feature-detail pro-temp-table-td"
                  style={{
                    padding: "10px",
                    width: "40%",
                    minHeight: "80px",
                  }}
                >
                  {row[0]}
                </td>
                <td
                  className="pro-temp-table-second-bg pro-temp-table-td"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <span role="img" aria-label="check">
                    {row[1] ? "✅" : "❌"}
                  </span>
                </td>
                <td
                  className="pro-temp-table-td"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <span role="img" aria-label="check">
                    {row[2] ? "✅" : "❌"}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProTemplateComparisonTable;
