import React, { useEffect, useState } from "react";
import { FaCrown, FaInfoCircle } from "react-icons/fa";

import checkIcon from '../../assets/check-mark.svg'
import templatesIcon from "../../assets/subscription-blue.svg";
import CustomLoader from "../CustomLoader/CustomLoader";

const SubscriptionCard = (props) => {
    const {
        templateName,
        price,
        duration,
        features,
        discount,
        selected, setSelected, isMonthly,
        handlePlanSelect,
        loading, templateIndex, setTemplateIndex, index
    } = props
    const [actualPrice, setActualPrice] = useState()
    useEffect(() => {
        if (price === "Free") {
            setActualPrice(price)
        }
        else if (isMonthly) {
            setActualPrice(`$${price}`)

        } else if (!isMonthly) {
            setActualPrice(`$${(price * 12) - ((price * 12) * .25)}`)

        }
    }, [isMonthly])

    return (
        <div
            onClick={() => loading || selected === templateName ? null : handlePlanSelect(templateName, index)}
            className={`w-full cursor-pointer   mx-auto p-6 bg-white border-[2px] ${selected === templateName ? "border-purple-600" : "border-gray-200"} rounded-lg shadow-lg hover:border-purple-600`}>
            {/* Header Section */}
            <div className="flex items-center space-x-2">
                {templateName === "Unlimited" ? <span className="px-2 py-1 text-xs font-semibold text-purple-600 bg-purple-100 rounded-full flex items-center">
                    {templateName} <FaCrown className="ml-1" />
                </span>
                    : (
                        <span className="px-2 py-1 text-xs font-semibold text-[#253CA6] bg-[#BFDBFE] rounded-full flex items-center">
                            {templateName}
                        </span>
                    )}
            </div>

            {/* Pricing Section */}
            <h2 className="mt-4 text-4xl font-bold text-gray-800">{actualPrice}
            </h2>
            <span className="text-gray-600 text-sm">{isMonthly ? "/month" : "/annual"}</span>
            <p className="text-sm text-gray-500 mt-1">(Charged {isMonthly ? "monthly" : "annually"})</p>

            {/* <div className="mt-4">

                <p className="text-sm text-green-500 mt-2">
                    Save up to {discount} on an annual plan
                </p>
            </div> */}

            {/* Select Plan Button */}
            {
                loading && index === templateIndex ? <button className={`w-full flex justify-center items-center mt-4 px-4 py-2 text-purple-600 border-[2px] border-purple-600  rounded-lg font-semibold  !bg-purple-600 hover:!text-white`}>
                    <CustomLoader width="25px" color="white" />
                </button> :
                    <button className={`w-full mt-4 px-4 py-2 text-purple-600 border-[2px] border-purple-600 ${templateName === 'Unlimited' && "bg-purple-600 !text-white"}  rounded-lg font-semibold hover:bg-purple-600 hover:!text-white`}>
                        {selected == templateName ? "Currently active": "Select plan"}
                    </button>
            }


            {/* Features Section */}
            <ul className="mt-6 space-y-4 text-gray-700 p-0">
                {
                    features.map((feature, index) => (
                        <li key={index} className="flex items-start space-x-2">
                            <span className={` w-6 h-6 flex items-center ${templateName === 'Unlimited' && index === features.length - 1 || templateName === 'Unlimited' && index === features.length - 2 ? "text-purple-600 icon-bg-light-blue" : "text-gray-500 icon-bg-light-grey"} justify-center `}>
                                {/* {feature.icon} */}
                                {
                                    templateName === 'Basic' && index === features.length - 1 ?
                                        <FaInfoCircle /> : templateName === 'Unlimited' && index === features.length - 2 ?
                                            <img className="w-4 h-4" src={templatesIcon} alt="" />

                                            : templateName === 'Unlimited' && index === features.length - 1 ? <FaCrown className="" /> :
                                                <img className="w-4 h-4" src={checkIcon} alt="" />

                                }
                            </span>
                            <p>{feature.featureDetails}</p>
                        </li>
                    ))
                }


            </ul>
        </div>
    )
}
export default SubscriptionCard

