import React from "react";

const ProTemplateFeatures = ({
  features,
  featureSection,
  featureHeadingSection,
  handleSectionEditChange,
}) => {
  return (
    <div className="pro-temp-features-container">
      <div className="flex w-full !justify-center text-center">

        <h2
          className={`pro-tmp-heading  ${featureHeadingSection ? "outline-border-selected" : "outline-border"
            }`}
          onClick={() => handleSectionEditChange(["featureHeadingSection"])}
        >
          {features.heading}
        </h2>
      </div>
      <div
        onClick={() => handleSectionEditChange(["featuresSection"])}
        className={`pro-tmp-feature-container  ${featureSection ? "outline-border-selected" : "outline-border"
          }`}
      >
        {features?.data?.map((feature, index) => {
          return (
            <div className="pro-tmp-feature-item" key={index}>
              <div className="pro-temp-feature-title-container">
                <h3 className="pro-tmp-feature-heading">{feature.title}</h3>
              </div>
              {/* <div className="pro-temp-feature-details-container">
                <p className="pro-tmp-feature-text">{feature.details}</p>
              </div> */}
            </div>
          );
        })}
        {features?.data?.map((feature, index) => {
          return (
            <div className="pro-tmp-feature-item" key={index}>
              
                <p className="pro-tmp-feature-text">{feature.details}</p>
            </div>
          );
        })}
        {/* <div className="pro-tmp-feature-item">
          <h3 className="pro-tmp-feature-heading">Feature Title</h3>
          <p className="pro-tmp-feature-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default ProTemplateFeatures;
