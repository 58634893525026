import { createSlice } from "@reduxjs/toolkit";

const templateSlice = createSlice({
    name: "templateSlice",

    initialState: {
        templateType: 'pro',
    },
    reducers: {
         
        setTemplateView: (state, action) => {
            state.templateType = action.payload
        },

    }

});

export const {
    setTemplateView
} = templateSlice.actions;

export default templateSlice.reducer;

