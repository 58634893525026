import React from "react";

const PetsKidsTemplateServices = ({
  recommendedHeadline,
  recommendedBullets,
  recommendedHeadlineSection,
  recommendedBulletsSection,
  handleSectionEditChange,
}) => {
  return (
    <section
      className="custom-content-section "
      style={{ backgroundColor: "" }}
    >
      <div className="page-width">
        <div className={`custom-content-wrapper  `}>
          <div
            onClick={() =>
              handleSectionEditChange(["recommendedHeadlineSection"])
            }
            className={`mb-2 cursor-pointer   ${
              recommendedHeadlineSection
                ? "outline-border-selected"
                : "outline-border"
            } `}
          >
            <h2
              className={` custom-content-section-heading  `}
              style={{ color: "#000000" }}
            >
              <span>{recommendedHeadline}</span>
            </h2>
          </div>
          <div
            onClick={() =>
              handleSectionEditChange(["recommendedBulletsSection"])
            }
            className={`cursor-pointer custom-content-body ${
              recommendedBulletsSection
                ? "outline-border-selected"
                : "outline-border"
            } `}
          >
            {recommendedBullets &&
              recommendedBullets.length > 0 &&
              recommendedBullets.map((recommendedBullet, index) => {
                let formattedText = recommendedBullet.startsWith("✓ ")
                  ? recommendedBullet : `✓ ${recommendedBullet}`;
                return (
                  <div key={index} className="custom-content-block">
                    <div className="custom-content-icon"></div>
                    <p className="custom-content-description">
                      {formattedText}
                    </p>
                  </div>
                );
              })}
          </div>
          <div className="features-custom-btn">
            <a href="javascript:void(0);" className="custom-content-button">
              ADD TO CART
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PetsKidsTemplateServices;
