import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import leftArrow from "../../assets/chevron-left.png"; // path to the uploaded image
import rightArrow from "../../assets/chevron-right.png"; // duplicate and rotate the image for the right arrow
const ProTemplateCarousel = ({
  testimonials,
  carouselSection,
  handleSectionEditChange,
}) => {
  
  
  const NextArrow = (props) => {
    const { onClick, style, className } = props;
    return (
      <div
        onClick={onClick}
        style={{
          ...style,
          backgroundImage: `url(${rightArrow})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
        }}
        className={className}
      />
    );
  };

  const PrevArrow = (props) => {
    const { onClick, style, className } = props;
    return (
      <div
        onClick={onClick}
        style={{
          ...style,
          display: "block",
          backgroundImage: `url(${leftArrow})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          fill: "white",
          cursor: "pointer",
        }}
        className={className}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };


  return (
    <div
      className={`slider-container mt-4 mb-[50px] ${
        carouselSection ? "outline-border-selected" : "outline-border"
      }`}
      onClick={() => handleSectionEditChange(["carouselSection"])
        
      }
    >
      <Slider {...settings}>
        {testimonials.map((content, index) => {
          return (
            <div className="bg-red px-3 ">
              <img
                className="pro-template-carousel-image"
                src={content.img??'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png'}
                // src={content.img}
                alt=""
              />
              <p className="pro-template-carousel-name">{content.name}</p>
              <h3 className="pro-template-carousel-details">
                {content.details}
              </h3>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
export default ProTemplateCarousel;
