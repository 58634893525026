import React from "react";

const PetsKidsTemplateDescribeProduct = ({
  answerBenefits,
  questionHeadline,
  handleSectionEditChange,

  questionHeadlineSection,
  answerBenefitsTopLeftSection,
  answerBenefitsBottomLeftSection,
  answerBenefitsTopRightSection,
  answerBenefitsBottomRightSection,
  customSalesSectionImage,
  customSalesImageSection,
}) => {
  return (
    <section className="custom-section-type-2 ">
      <div className="page-width">
        <div className="flex justify-center">
          <h2
            onClick={() => handleSectionEditChange(["questionHeadlineSection"])}
            className={`pro-tmp-heading cursor-pointer ${
              questionHeadlineSection
                ? "outline-border-selected"
                : "outline-border"
            } `}
          >
            {questionHeadline}
          </h2>
        </div>
        <div className="custom-section-content-type-2">
          <div className="custom-blocks-wrapper-type-2">
            {/* Left Blocks */}
            <div className="custom-left-blocks-type-2">
              {/* top left */}
              <div
                onClick={() =>
                  handleSectionEditChange(["answerBenefitsTopLeftSection"])
                }
                className={`cursor-pointer ${
                  answerBenefitsTopLeftSection
                    ? "outline-border-selected"
                    : "outline-border"
                } custom-block-type-2`}
              >
                <div className="summary__title">
                  <h3>
                    {answerBenefits[0]?.title.startsWith("✓ ")
                      ? answerBenefits[0]?.title
                      : "✓ " + answerBenefits[0]?.title}
                  </h3>
                </div>
                <p>{answerBenefits[0]?.description}</p>
              </div>
              {/* bottom left */}
              <div
                onClick={() =>
                  handleSectionEditChange(["answerBenefitsBottomLeftSection"])
                }
                className={`cursor-pointer ${
                  answerBenefitsBottomLeftSection
                    ? "outline-border-selected"
                    : "outline-border"
                } custom-block-type-2`}
              >
                <div className="summary__title">
                  <h3>{answerBenefits[1]?.title.startsWith("✓ ")
                      ? answerBenefits[1]?.title
                      : "✓ " + answerBenefits[1]?.title}</h3>
                </div>
                <p>{answerBenefits[1]?.description}</p>
              </div>
            </div>
            {/* Center Image */}
            <div
              onClick={() =>
                handleSectionEditChange(["customSalesSectionImage"])
              }
              className={`cursor-pointer p-1 ${
                customSalesImageSection
                  ? "outline-border-selected"
                  : "outline-border"
              } `}
            >
              <div className={`custom-center-image-type-2  `}>
                <img src={customSalesSectionImage} alt="Center Image" />
              </div>
            </div>

            {/* Right Blocks */}
            <div className="custom-right-blocks-type-2">
              {/* top right  */}
              <div
                onClick={() =>
                  handleSectionEditChange(["answerBenefitsTopRightSection"])
                }
                className={`cursor-pointer ${
                  answerBenefitsTopRightSection
                    ? "outline-border-selected"
                    : "outline-border"
                } custom-block-type-2`}
              >
                <div className="summary__title">
                  <h3>{answerBenefits[2]?.title.startsWith("✓ ")
                      ? answerBenefits[2]?.title
                      : "✓ " + answerBenefits[2]?.title}</h3>
                </div>
                <p>{answerBenefits[2]?.description}</p>
              </div>
              {/* bottom right */}
              <div
                onClick={() =>
                  handleSectionEditChange(["answerBenefitsBottomRightSection"])
                }
                className={`cursor-pointer ${
                  answerBenefitsBottomRightSection
                    ? "outline-border-selected"
                    : "outline-border"
                } custom-block-type-2`}
              >
                <div className="summary__title">
                  <h3>{answerBenefits[3]?.title.startsWith("✓ ")
                      ? answerBenefits[3]?.title
                      : "✓ " + answerBenefits[3]?.title}</h3>
                </div>
                <p>{answerBenefits[3]?.description}</p>
              </div>
            </div>
          </div>
        </div>
        {/* Button */}
        <div className="custom-button-wrapper-type-2">
          <a href className="custom-button-type-2">
            Add to Cart
          </a>
        </div>
      </div>
    </section>
  );
};

export default PetsKidsTemplateDescribeProduct;
