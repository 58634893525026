import React from 'react'

const ProTemplateTableLabelEditComponent = ({tableData,setTableData}) => {
  return (
  
    <>
    <div className="title-container">Table Columns</div>
    <div className="input-main-container">
      {tableData.tableProperties.map((name, index) => (
        <div className="input-container">
          <label className="input-label">{name}</label>
          <input
            className="form-input"
            type="text"
            value={name}
            onChange={(e) =>
              setTableData(() => {
                let data = [...tableData.tableProperties];
                data[index] = e.target.value;
                return { ...tableData, tableProperties: data };
              })
            }
          />
        </div>
      ))}
    </div>
  </>
)
}

export default ProTemplateTableLabelEditComponent