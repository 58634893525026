import React from "react";

const PrecautionSectionEditComponent = ({
  reviewKey,
  reviews,
  setReviews }) => {

  const handleUpdate = ({ value }) => {
    setReviews(prev => ({ ...prev, [reviewKey]: value }));
  };
  return (
    <>
      <div className="title-container">Note Details </div>
      <div className="input-main-container">
        <div className="input-container" >
          <label className="input-label">Note </label>
          <textarea
            rows="6"
            className="form-input"
            type="text"
            value={reviews[reviewKey]}
            onChange={(e) =>
              handleUpdate({ value: e.target.value })
            }
          />

        </div>
      </div>
    </>
  );
};

export default PrecautionSectionEditComponent;
