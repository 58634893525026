import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { message, Switch } from "antd";
import trashbin from "../../../../assets/trashbin.svg";

const ProTemplateTableRowEditComponent = ({
  tableData,
  setTableData,
  tableRowIndex,
  setTableRowIndex
}) => {
  const handleDeleteRow = () => {
    if(tableData.tableRows.length === 1) return message.info("You can't delete last row!");
    const newTableData = {
      ...tableData,
      tableRows: tableData.tableRows.filter(
        (row, index) => index !== tableRowIndex
      ),
    };
    setTableData(newTableData);
    setTableRowIndex(null);
  };

  return tableRowIndex === null ? null:(
    <>
      <div className="title-container">
        Table Rows
        {/* <img width="30px" src={trashbin} alt="delete" /> */}
      </div>

      <div className="input-main-container">
        <div className="input-container">
          <div className="flex justify-between items-center">
            <label className="input-label">{`Edit Row # ${
              tableRowIndex + 1
            }`}</label>{" "}
            <button
              className="gallery-image-delete-button"
              onClick={handleDeleteRow}
              danger
            >
              <img width="30pxF" src={trashbin} alt="delete" />
            </button>
          </div>

          <input
            className="form-input"
            type="text"
            value={tableData.tableRows[tableRowIndex][0]}
            onChange={(e) =>
              setTableData((prevData) => {
                const updatedRows = [...prevData.tableRows];
                const updatedRow = [...updatedRows[tableRowIndex]];
                updatedRow[0] = e.target.value;
                updatedRows[tableRowIndex] = updatedRow;

                return {
                  ...prevData,
                  tableRows: updatedRows,
                };
              })
            }
          />
        </div>
        <div className="input-container">
          <label className="input-label">{tableData.tableProperties[0]}</label>
          <Switch
            className=" pro-temp-table-switch-styles "
            checked={tableData.tableRows[tableRowIndex][1]}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={() => {
              const updatedRows = [...tableData.tableRows];
              const updatedRow = [...updatedRows[tableRowIndex]];
              updatedRow[1] = !updatedRow[1];
              updatedRows[tableRowIndex] = updatedRow;
              setTableData({ ...tableData, tableRows: updatedRows });
            }}
          />
        </div>
        <div className="input-container">
          <label className="input-label">{tableData.tableProperties[1]}</label>
          <Switch
            className=" pro-temp-table-switch-styles "
            checked={tableData.tableRows[tableRowIndex][2]}
            onChange={() => {
              const updatedRows = [...tableData.tableRows];
              const updatedRow = [...updatedRows[tableRowIndex]];
              updatedRow[2] = !updatedRow[2];
              updatedRows[tableRowIndex] = updatedRow;
              setTableData({ ...tableData, tableRows: updatedRows });
            }}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </div>
      </div>
    </>
  );
};

export default ProTemplateTableRowEditComponent;
