import React, { useState } from "react";
import trashbin from "../../../../assets/trashbin.svg";
import { message } from "antd";
import open from "../../../../assets/plus-collapse.svg";
import ProTemplateImageEdit from "./ProTemplateImageEdit";
import ProTemplateImageEditModal from "../ImagesGalleryModal/ProTemplateImageEditModal";

const ProTemplateCarouselEditComponent = ({
  testimonials,
   isCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  setReviews,
  setTestimonials,
  setTextOnImageDetails,
  setSectionsImages,
}) => {
  const [addTestimonial, setAddTestimonial] = useState(false)
  const handleUpdate = ({ key, index, value }) => {
    const newContent = [...testimonials];
    newContent[index]={
      ...newContent[index],
      [key] :value
    };
    setTestimonials(newContent);

    // setTestimonials({})
  };
  const handleDeleteRow = (index) => {
    if (testimonials.length === 3)
      return message.info("You can't delete last 3 Testimonials!");
    const newCarouselData = testimonials.filter((row, i) => i !== index);

    setTestimonials(newCarouselData);
    // setTableRowIndex(null);
  };
  const [images, setImages] = useState([])

  return (
    <div className="">
      <div className="title-container !w-[25%] ">
        <p>
          Testimonials
        </p>

        <button

          className="gallery-image-delete-button !w-auto !bg-[#F2F2F2] !border-[black] text-[black] index-[5]  p-2 gap-1"
          onClick={() => testimonials.length >= 10 ? message.info('Can not add more than 10 testimonials!') : setAddTestimonial(prev => !prev)}

        >
          {
            addTestimonial ? "Cancel" : <>
              <img
                width={"20px"}
                height={"20px"}
                src={open}
                alt="Toggle Icon"
              />
              Add Testimonial
            </>

          }
        </button>
      </div>
      {
        addTestimonial ? <AddNewTestimonial {...{
          setAddTestimonial,
          setTestimonials, isCanvaConnected,
          awaitingRefresh,
          setAwaitingRefresh,
          isEditCanvaLoading,
          setIsEditCanvaLoading
        }} /> :

          <div className="input-main-container ">
            {testimonials.map((item, index) => {
              return (<>
                <div className={`${index==0&&"!pt-[40px] "} input-container`}key={index}>

                  <div className="flex justify-between items-center">
                    <label className="input-label label-weight">{`Testimonial Content # ${index + 1
                      }`}</label> 
                    <button
                      className="gallery-image-delete-button"
                      onClick={() => handleDeleteRow(index)}
                      danger
                    >
                      <img width="30pxF" src={trashbin} alt="delete" />
                    </button>
                  </div>
                  <ProTemplateImageEdit
                  {...{setReviews,
                    setTestimonials,
                    setTextOnImageDetails,
                    setSectionsImages}}

                    image={item.img}
                    setProductImages={setTestimonials}
                    isCanvaConnected={isCanvaConnected}
                    awaitingRefresh={awaitingRefresh}
                    setAwaitingRefresh={setAwaitingRefresh}
                    isEditCanvaLoading={isEditCanvaLoading}
                    setIsEditCanvaLoading={setIsEditCanvaLoading}
                    imageIndex={index}
                    toWhich={'carousel'}
                  />

                  <input
                    className="form-input"
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      handleUpdate({ key: "name", index, value: e.target.value })
                    }
                  />
                  <textarea
                    rows="6"
                    className="form-input"
                    value={item.details}
                    onChange={(e) =>
                      handleUpdate({ key: "details", index, value: e.target.value })
                    }
                  />
                </div>
              </>
              );
            })}
          </div>
      }
    </div>

  );
};

export default ProTemplateCarouselEditComponent;
const AddNewTestimonial = ({
  setTestimonials,
  isCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  setAddTestimonial

}) => {
  const [newData, setNewData] = useState({
    name: '',
    details: '',
    img: ''
  })
  const handleAddTestimonial = () => {
    setTestimonials((prev) => {
      const oldData = [...prev];
      oldData.push(newData);
      return oldData
    })
    setNewData({
      name: '',
      details: '',
      img: null
    })
    setAddTestimonial(false)
    return message.success('New testimonial added successfully!')
  }
  return (
    <div className="input-container !pt-[48px]" >
      <div className="flex justify-between items-center">
        <label className="input-label">Add New Testimonial </label>

      </div>
      <ProTemplateImageEdit
        image={newData.img}
        setProductImages={setNewData}
        isCanvaConnected={isCanvaConnected}
        awaitingRefresh={awaitingRefresh}
        setAwaitingRefresh={setAwaitingRefresh}
        isEditCanvaLoading={isEditCanvaLoading}
        setIsEditCanvaLoading={setIsEditCanvaLoading}
        imageIndex={0}
        toWhich={'textOnImage'}

      />
      <input
        className="form-input"
        type="text"
        placeholder="Enter Name..."
        value={newData.name}
        onChange={(e) =>
          setNewData(prev => ({ ...prev, name: e.target.value }))
        }
      />
      <textarea
        rows="6"
        className="form-input"
        placeholder="Enter testimonial details..."

        value={newData.details}
        onChange={(e) =>
          setNewData(prev => ({ ...prev, details: e.target.value }))
        }
      />
      <div className="w-full my-5  ">
        <button
          onClick={handleAddTestimonial}
          type="button"
          className="top-container-right-btns-container-button w-full disabled:!cursor-not-allowed"
          disabled={newData.name === "" || newData.details === ""}
        >
          Add
        </button>
      </div>
    </div>
  )
}