import PetsKidsMainTestimonialImageEdit from "./PetsKidsMainTestimonialImageEdit";

const PetsKidsMainTestimonialEditComponent = ({
    handleInputChange,
    testimonialMainSectionText,
    testimonialMainSectionName,
    image,
    updateProductData,
    setSectionsImages,
    isCanvaConnected,
    awaitingRefresh,
    setAwaitingRefresh,
    isEditCanvaLoading,
    setIsEditCanvaLoading,
}) => {
    return (
        <>
            <div className="title-container">Image Content</div>

            <div className="input-container">

                <label className="input-label">Edit Content</label>
                <PetsKidsMainTestimonialImageEdit
                    image={image}
                    setProductImages={setSectionsImages}
                    isCanvaConnected={isCanvaConnected}
                    awaitingRefresh={awaitingRefresh}
                    setAwaitingRefresh={setAwaitingRefresh}
                    isEditCanvaLoading={isEditCanvaLoading}
                    setIsEditCanvaLoading={setIsEditCanvaLoading}
                    imageIndex={0}
                    toWhich={'mainTestimonialSectionImage'} />

                <div className="input-container !px-0">
                    <label className="input-label">Name</label>
                    <input
                        className="form-input"
                        type="text"
                        value={testimonialMainSectionName}
                        onChange={(e) =>
                            updateProductData((prev) => {
                              const newTestimonials = [...prev.testimonialWriters];
                              newTestimonials[0] = {
                                ...newTestimonials[0],
                                name: e.target.value,
                              };
                              return { ...prev,testimonialWriters:newTestimonials};
                            })
                          }
                    />
                </div>
                <div className="input-container !px-0">
                    <label className="input-label">Details</label>
                    <textarea
                        rows="6"
                        className="form-input"
                        value={testimonialMainSectionText}
                        onChange={(e) =>
                            handleInputChange("testimonial", e.target.value)
                        }
                    />
                </div>
            </div>
        </>
    );
};
export default PetsKidsMainTestimonialEditComponent;
