const ProTemplateCollpseHeadingEditComponent = ({
  FAQData,
  setFAQData,
}) => {
  return (
    <>
      <div className="title-container">Collapse Heading</div>
      <div className="input-main-container">
        <div className="input-container">
          <label className="input-label">Heading</label>
          <input
            className="form-input"
            type="text"
            value={FAQData?.heading}
            onChange={(e) =>
              setFAQData((prev) => ({ ...prev, heading: e.target.value }))
            }
          />
        </div>
      </div>
    </>
  );
};
export default ProTemplateCollpseHeadingEditComponent;
