const ProTemplateTabsEditComponent = ({ tabsContent, setTabsContent }) => {
  return (
    <>
      <div className="title-container">Tabs Content</div>
      {tabsContent.map((tab, index) => (
        <div className={`${index==0 ? "input-main-container" :''}`}>
          <div className="input-container">
            <label   className="input-label label-weight">{`Tab # ${index+1}`}</label>
            <input
              className="form-input"
              type="text"
              value={tab.heading}
              onChange={(e) =>
                setTabsContent((prev) => {
                  const data = [...prev];
                  data[index] = { ...data[index], heading: e.target.value };
                  return data;
                })
              }
            />
          </div>
          <div className="input-container !mt-2">
            <textarea
            rows={'3'}
              className="form-input"
              type="text"
              value={tab.details}
              onChange={(e) =>
                setTabsContent((prev) => {
                  const data = [...prev];
                  data[index] = { ...data[index], details: e.target.value };
                  return data;
                })
              }
            />
          </div>
        </div>
      ))}
    </>
  );
};
export default ProTemplateTabsEditComponent;
