 
const ProTemplateSectionImages = ({ sectionData }) => {
  return sectionData.map((content, index) => (
    <div key={index} className={`pro-temp-section-image-container `}>
      {index!==1 &&<div className="pro-temp-product-image">
        <img className="pro-template-section-image"  src={content.img} alt={`section image ${index}`} />
      </div>}
      <div className="pro-template-product-info">
        <h2>{content.heading}</h2>
        <p className="pro-template-detail-text">{content.details}</p>
      </div>
      {index===1 &&<div className="pro-temp-product-image">
        <img className="pro-template-section-image" src={content.img}  alt={`section image ${index}`} />
      </div>}
    </div>
  ));
};
export default ProTemplateSectionImages;
