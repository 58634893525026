export const URLS = {
  BACKEND_API:
    window.location.hostname === "localhost"
      ? "http://localhost:8787"
      : "https://backend.sofia-app.io",
  CANVA_BACKEND_API:
    window.location.hostname === "localhost"
      ? "http://127.0.0.1:8787"
      : "https://backend.sofia-app.io",
  ALIEXP_CALLBACK_URL: "/aliexpress/callback",
  SOFIA_WEB:
    window.location.hostname === "localhost"
      ? "http://localhost:3000"
      : "https://app.sofia-app.io/",
};
