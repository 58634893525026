const ProTemplatePrecautionDeatils = ({
  reviews,
  setReviewKey,
  handleSectionEditChange,
  reviewsSection,
  reviewKey,
  reviewsIconSections

}) => {
  return (
    <div className="pro-tem-note-container">
      <p
        onClick={() => handleSectionEditChange(["reviewsSection"])}
        className={`pro-tem-note-text ${reviewsSection ? "outline-border-selected" : "outline-border"
          }`}
      >
        {reviews[reviewKey]}
      </p>
      <div className={`flex w-full  gap-3 items-center justify-center mt-[160px] `}>
        <div onClick={()=>handleSectionEditChange(['reviewsIconSections'])} className={`col-4 flex
        ${reviewsIconSections ? "outline-border-selected" : "outline-border"}`}>
          {reviews.reviewImages.map((img, index) => img.show && (
            <img
              onClick={() => setReviewKey(`${index }`)}
              key={index}
              className="pro-tem-note-image cursor-pointer"
              src={img.img}
              alt={`note details image ${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default ProTemplatePrecautionDeatils;
