import React from 'react'

const KidsPetsTemplateSectionUnderPriceAndHeading = (
  {
    salesTextHeadline1,
    salesText1,
    secondSectionImage,
    handleSectionEditChange,
    salesTextHeadline1Section,
    salesText1Section,
  }

) => {
  return (
    <section className="image-text-section-type-2 px-[50px] w-full">
      <div className="page-width">
        <div className='flex w-full !justify-center text-center'>

          <h2
            onClick={() => handleSectionEditChange(["salesTextHeadline1Section"])}
            className={`pro-tmp-heading cursor-pointer ${salesTextHeadline1Section ? "outline-border-selected"
              : "outline-border"}`}>{salesTextHeadline1}</h2>
        </div>
        <div className="content-wrapper-type-2 image-left">
          <div className={`image-container-type-2 cursor-pointer ${secondSectionImage ? "outline-border-selected"
              : "outline-border"}`}
            onClick={() => handleSectionEditChange(["secondSectionImage"])}
          
          >
            <img src={secondSectionImage} alt="Section Image" />
          </div>

          <div
            onClick={() => handleSectionEditChange(["salesText1Section"])}
            className={`text-container-type-2 text-black cursor-pointer ${salesText1Section ? "outline-border-selected"
              : "outline-border"}`}
            style={{ color: "rgba(18, 18, 18, 0.75" }} dangerouslySetInnerHTML={{ __html: salesText1 }}>

          </div>
        </div>
        <div className="button-container-type-2">
          <a href className="cta-button-type-2">Add to Cart</a>
        </div>
      </div>
    </section>

  )
}

export default KidsPetsTemplateSectionUnderPriceAndHeading