import React, { useState, useEffect, useCallback, useRef } from "react";
import "./RightSection.css";
import {  Switch } from "antd";
import SectionEditImageContainer from "./SectionEditImageContainer";
import GalleryEditImageContainer from "./GalleryEditImageContainer/GalleryEditImageContainer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ProductVariantsComponent from "../ProductVariantsSection/ProductVariantsComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  changeProductBullets,
   setToUpdateImageDetails,
   updateVariantImageFromCanvaEdit,
} from "../../../redux/slices/product-variants-slice";
import getAliExpressPrices from "../../../Utilities/productVariantsHandlers/getAliExpressPrices";
import { URLS } from "../../../URLS";
import { callBackendAPI } from "../../../helpers/apiCalls";
import getUpdatedArraysAfterCanvaEditing from "../../../Utilities/getUpdatedArraysAfterCanvaEditing";
import ConnectCanva from "../../Canva/ConvaConnect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProTemplateCarouselEditComponent from "./ProTemplateEditComponents/ProTemplateCarouselEditComponent";
import PrecautionSectionEditComponent from "./ProTemplateEditComponents/PrecautionSectionEditComponent";
import ProTemplateFeaturesEditComponent from "./ProTemplateEditComponents/ProTemplateFeaturesEditComponent";
import ProTemplateTableRowEditComponent from "./ProTemplateEditComponents/ProTemplateTableRowEditComponent";
import ProTemplateTableLabelEditComponent from "./ProTemplateEditComponents/ProTemplateTableLabelEditComponent";
import ProTemplateTextOnImageEditComponent from "./ProTemplateEditComponents/ProTemplateTextOnImageEditComponent";
import ProTemplateCompanyNameEditComponent from "./ProTemplateEditComponents/ProTemplateCompanyNameEditComponent";
import ProTemplateAddRowEditComponent from "./ProTemplateEditComponents/ProTemplateAddRowEditComponent";
import ProTemplateCollpseContentEditComponent from "./ProTemplateEditComponents/ProTemplateCollpseContentEditComponent";
import ProTemplateCollpseHeadingEditComponent from "./ProTemplateEditComponents/ProTemplateCollpseHeadingEditComponent";
import ProTemplateTabsEditComponent from "./ProTemplateEditComponents/ProTemplateTabsEditComponent";
import ProTemplateIconEditSection from "./ProTemplateEditComponents/ProTemplateIconEditSection";
import ProTemplateTableHeadingEditComponent from "./ProTemplateEditComponents/ProTemplateTableHeadingEditComponent";

const convertBulletPointsToHtml = (bulletPoints) => {
  const htmlString = `<ul>${bulletPoints
    .map((point) => `<li>${point}</li>`)
    .join("")}</ul>`;

  return htmlString;
};

const parseBulletPoints = (htmlString) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  const bulletPoints = Array.from(tempDiv.querySelectorAll("li")).map(
    (li) => li.textContent
  );
  return bulletPoints;
};

const ProTemplateRightSection = ({
  productPrice,
  setProductPrice,
  productData,
  sectionClick,
  updateProductData,
  showSalePrice,
  setShowSalePrice,
  sectionsImages,
  setSectionsImages,
  productImages,
  setProductImages,
  aliexpressImages,
  setVariantViewChange,
  variantView,
  shipsFrom,
  imageCache,
  tabsContent,
  setTabsContent,
  testimonials,
  reviews,
  reviewKey,
  setReviewKey,
  features,
  setFeatures,
  textOnImageDetails,
  setTestimonials,
  setReviews,
  setTextOnImageDetails,
  tableData,
  setTableData,
  tableRowIndex,
  setTableRowIndex,
  companyName,
  setCompanyName,
  FAQData,
  setFAQData,
  collapseIndex,
}) => {
  // const { matchedVariant } = useSelector(store => store.productVariants)
  const dispatch = useDispatch();

  const {
    productBulletBenefits,
    productData: CompleteProductData,
    toUpdateImageDetails,
    changeVariantImageKey,
  } = useSelector((store) => store?.productVariants);

  const [bulletPoints, setBulletPoints] = useState([]);
  const [selectImageInfo, setSelectImageInfo] = useState({});
  const [grossProfit, setGrossProfit] = useState(0);
  const [isCanvaConnected, setIsCanvaConnected] = useState(false);
  const [canvaAuthWindow, setCanvaAuthWindow] = useState(null);
  const [isEditCanvaLoading, setIsEditCanvaLoading] = useState(false);
  const [awaitingRefresh, setAwaitingRefresh] = useState(false);
  const [updatedUrl, setUpdatedUrl] = useState("");

  useEffect(() => {
    if (updatedUrl !== "") {
      // update every where
      const prevUrl = toUpdateImageDetails?.imageUrl;
      const newUrl = updatedUrl;
      const { updatedProductImages, updatedSectionsImages } =
        getUpdatedArraysAfterCanvaEditing({
          productImages,
          sectionsImages,
          imageUrl: prevUrl,
          updatedUrl,
        });

      setTestimonials((prevTestimonials) =>
        prevTestimonials.map((testimonial) =>
          testimonial.img === prevUrl
            ? { ...testimonial, img: newUrl }
            : testimonial
        )
      );
      setReviews((prevReviews) => ({
        ...prevReviews,
        reviewImages: prevReviews.reviewImages.map((reviewImage) =>
          reviewImage.img === prevUrl
            ? { ...reviewImage, img: newUrl }
            : reviewImage
        ),
      }));
      setTextOnImageDetails((prevDetails) => ({
        ...prevDetails,
        img: prevDetails.img === prevUrl ? newUrl : prevDetails.img,
      }));

      // Set the state for both
      setProductImages(updatedProductImages);
      setSectionsImages(updatedSectionsImages);
      dispatch(
        updateVariantImageFromCanvaEdit({
          existingUrl: toUpdateImageDetails?.imageUrl,
          newUrl: updatedUrl,
        })
      );
    }
  }, [updatedUrl]);

  const quillRef = useRef(null);
  const reactQuillRef = useCallback((node) => {
    console.log("react quill reff:", node);

    if (node !== null) {
      quillRef.current = node.getEditor();
    }
  }, []);

  useEffect(() => {
    if (productBulletBenefits) {
      setBulletPoints(parseBulletPoints(productBulletBenefits));
    }
    if (productData.price && productData.salePrice) {
      setShowSalePrice(!!productData.salePrice);
    }
  }, [productData, sectionClick]);
  useEffect(() => {
    const grossPrice = getAliExpressPrices(CompleteProductData);

    if (Object.keys(grossPrice)[0]) {
      const firstKey = Object.keys(grossPrice)[0];
      const setPrice =
        showSalePrice && productPrice.salePrice > 0
          ? productPrice.salePrice - grossPrice[firstKey]?.price
          : productPrice.price > 0
          ? productPrice.price - grossPrice[firstKey]?.price
          : 0;

      setGrossProfit(setPrice.toFixed(2));
     }
  }, [productPrice]);
  useEffect(() => {
    const trueKey = Object.keys(sectionClick).find((key) => sectionClick[key]);
if(trueKeys.length > 0&&trueKeys[0]==="section6Image"){

  setSelectImageInfo({ status: true, trueKey: "section6Image"});
}
   else if (trueKey && sectionsImages[trueKey]) {
      setSelectImageInfo({ status: true, trueKey });
    } else {
      setSelectImageInfo({});
    }
  }, [sectionClick, sectionsImages]);
  useEffect(() => {
    const abortController = new AbortController();
    callBackendAPI("canva", "GET", {}, null, abortController.signal).then(
      (response) => {
        if (!!response.connected) {
          setIsCanvaConnected(true);
        }
      }
    );

    const canvaReturnFunc = (event) => {
      console.log("canva event on return= >", event);

      if (
        event.origin === URLS.CANVA_BACKEND_API &&
        event.data.event === "canva-return"
      ) {
        if (
          event.data.data &&
          event.data.data.design_id &&
          event.data.data.correlation_state?.length > 0
        ) {
          console.log("canva event on return if condition= >");
          setIsEditCanvaLoading(true);
          callBackendAPI("canva/design", "GET", {
            designId: event.data.data.design_id,
            update: true,
          })
            .then((response) => {
              if (response.status === "success") {
             
                if (response.accessToken) {
                  localStorage.setItem(
                    "canva_access_token",
                    response.accessToken
                  );
                }

                console.log("Canva return success");
                console.log("canva/design", response, toUpdateImageDetails);
                setUpdatedUrl(response?.url);
                console.log("awaitingRefresh", awaitingRefresh);
                setAwaitingRefresh(false);
              }
            })
            .finally(() => {
              setIsEditCanvaLoading(false);
            });
        }
      }
    };

    window.addEventListener("message", canvaReturnFunc);

    return () => {
      abortController.abort();

      if (canvaAuthWindow) {
        canvaAuthWindow.close();
      }
      window.removeEventListener("message", canvaReturnFunc);
    };
  }, []);

  const canvaEventFunc = (event) => {
    if (event.origin === URLS.CANVA_BACKEND_API) {
      if (event.data.event === "canva-auth" && event.data.canva) {
        switch (event.data.canva.status) {
          case "success":
            setIsCanvaConnected(true);
            break;
          case "error":
            console.error("Canva auth failed");
            console.error(event.data.canva.error);
            break;
          default:
            break;
        }
        setCanvaAuthWindow(null);
      }

      window.removeEventListener("message", canvaEventFunc);
    }
  };

  const handleCanva = () => {
    if (!!isCanvaConnected) {
      callBackendAPI("canva", "DELETE", {}).then((response) => {
        if (response.status === "success") {
          setIsCanvaConnected(false);
          setCanvaAuthWindow(null);
        }
      });
      return;
    }

    if (canvaAuthWindow) {
      canvaAuthWindow.focus();
      return;
    }

    window.addEventListener("message", canvaEventFunc);

    callBackendAPI("canva/auth", "GET", {}).then((response) => {
      const popup = window.open(
        `${URLS.CANVA_BACKEND_API}/canva/redirect?user_id=${response.userId}&code_verifier=${response.codeVerifier}&redirect_url=${response.url}`,
        "Connect to Canva",
        "toolbar=no,location=no,menubar=no,titlebar=no,scrollbars=yes,resizable=yes,width=500,height=700"
      );

      setCanvaAuthWindow(popup);

      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
          window.removeEventListener("message", canvaEventFunc);
          setCanvaAuthWindow(null);
        }
      }, 1000);
    });
  };

  const updateBulletBenefits = (updatedBulletPoints) => {
    const updatedBulletBenefits =
      convertBulletPointsToHtml(updatedBulletPoints);
    dispatch(
      changeProductBullets(convertBulletPointsToHtml(updatedBulletPoints))
    );

    updateProductData((prevData) => ({
      ...prevData,
      bulletBenefits: updatedBulletBenefits,
    }));
  };

  const getTrueKeys = () => {
    return Object.keys(sectionClick).filter((key) => sectionClick[key]);
  };

  const handleBulletPointChange = (index, newValue) => {
    const updatedBulletPoints = [...bulletPoints];
    updatedBulletPoints[index] = newValue;
    setBulletPoints(updatedBulletPoints);
    updateBulletBenefits(updatedBulletPoints);

    const textarea = document.getElementById(`bulletPointTextarea_${index}`);
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (textarea.scrollHeight > 500) {
        textarea.style.height = "500px";
      }
    }
  };

  const trueKeys = getTrueKeys().filter(
    (key) => key !== "productName" && key !== "bulletBenefits"
  );

  const findPair = (keys) => {
    let headlineKey = null;
    let textKey = null;
    for (const key of keys) {
      if (key.includes("Headline")) {
        headlineKey = key;
      } else {
        textKey = key;
      }
    }
    return { headlineKey, textKey };
  };

  const { headlineKey, textKey } = findPair(trueKeys);

  const handleInputChange = (key, value) => {
    if (textKey === "satisfactionGuaranteeText") {
      setTextOnImageDetails((prev) => ({ ...prev, title: value }));
    }
    updateProductData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handlePriceChange = (key, value) => {
    if (isNaN(value)) return;
    value = value.replace(/^0+(?!$)/, "");

    if (value === "" || parseFloat(value) < 0) {
      value = "0";
    } else if (parseFloat(value) > 99999) {
      return; // Cap the value at ten thousand
    }
    if (key === "salePrice") {
      setProductPrice({ ...productPrice, salePrice: value });
    } else if (key === "price") {
      setProductPrice({ ...productPrice, price: value });
    }
  };

  const handleHideSalePrice = () => {
    if (showSalePrice) {
      setShowSalePrice(false);
      setProductPrice({ ...productPrice, enableSalePrice: false });
    } else {
      setShowSalePrice(true);
      setProductPrice({ ...productPrice, enableSalePrice: true });
    }
  };

  const modules = {
    toolbar: {
      container: [
        // [{ size: ["small", false, "large"] }],
        ["bold", "italic", "underline", "strike"],
      ],
    },
  };
console.log('setTextOnImageDetailssetTextOnImageDetailssetTextOnImageDetails',selectImageInfo,trueKeys);

  const formats = ["font", "size", "bold", "italic", "underline", "strike"];
  const processContent = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    const paragraphs = tempDiv.getElementsByTagName("p");
    const spans = paragraphs[0].getElementsByTagName("span");
    const allTags = paragraphs[0].querySelectorAll("*");
    Array.from(allTags).forEach((tag) => {
      if (tag.tagName.toLowerCase() !== "span") {
        tag.removeAttribute("class");
      }
    });
    Array.from(spans).forEach((span) => {
      const spanClass = span.className;
      const spanText = span.textContent;
      span.replaceWith(spanText);
      if (!paragraphs[0].classList.contains(spanClass)) {
        if (paragraphs[0].className) {
          paragraphs[0].className += `${spanClass}`;
        } else {
          paragraphs[0].className = spanClass;
        }
      }
    });

    return tempDiv?.innerHTML;
  };
  const handleEditorChange = useCallback(
    (content, delta, source, editor) => {
      let hasSizeAttribute = false;

      console.log("textkey:::", textKey);

      delta.ops.forEach((op) => {
        if (op.attributes && op.attributes.size) {
          // hasSizeAttribute = true;
          const finalContent = processContent(content);

          if (source === "user" && finalContent) {
            if (textKey === "satisfactionGuaranteeText") {
              setTextOnImageDetails((prev) => ({
                ...prev,
                details: finalContent || content,
              }));
            } else {
              updateProductData((prevData) => ({
                ...prevData,
                [textKey]: finalContent || content,
              }));
            }
          }
        }
      });

      if (!hasSizeAttribute && source === "user") {
        if (textKey === "satisfactionGuaranteeText") {
          setTextOnImageDetails((prev) => ({ ...prev, details: content }));
        } else {
          updateProductData((prevData) => ({
            ...prevData,
            [textKey]: content,
          }));
        }
      }
    },
    [productData, textKey, updateProductData]
  );

  return (
    <div className="right-section-main-container">
      {sectionClick?.productName && (
        <>
          <div className="title-container">Title</div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Header</label>
              <input
                className="form-input"
                type="text"
                value={productData?.productName}
                onChange={(e) =>
                  handleInputChange("productName", e.target.value)
                }
              />
            </div>
          </div>
        </>
      )}
      {sectionClick?.subTitleSection && (
        <>
          <div className="title-container">Product slogan</div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Sub Title</label>
              <input
                className="form-input"
                type="text"
                value={productData?.productSubTitle}
                onChange={(e) =>
                  handleInputChange("productSubTitle", e.target.value)
                }
              />
            </div>
          </div>
        </>
      )}

      {sectionClick?.bulletBenefits && bulletPoints && (
        <>
          <div style={{ marginBottom: "20px" }} className="title-container">
            Section bullet points
          </div>
          <div className="input-container" style={{ marginTop: "60px" }}>
            {bulletPoints?.map((point, index) => (
              <div className="mb-[12px]" key={index}>
                <label className="input-label">Text</label>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "flex-start",
                  }}
                >
                  <textarea
                    id={`bulletPointTextarea_${index}`}
                    className="form-input"
                    style={{
                      height: "auto",
                      resize: "none",
                      overflowY: "hidden",
                    }}
                    onPointerEnter={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                    value={point}
                    onChange={(e) =>
                      handleBulletPointChange(index, e.target.value)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {headlineKey && textKey && (
        <>
          <div className="title-container">
            {headlineKey === "guaranteeHeadline" ||
            headlineKey === "satisfactionGuaranteeHeadline"
              ? "Accordion"
              : "Section text"}
          </div>

          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">
                {headlineKey === "guaranteeHeadline" ? "Title" : "Heading"}
              </label>
              <input
                className="form-input"
                type="text"
                value={productData[headlineKey]}
                // onChange={(e) => setTextOnImageDetails(prev => ({ ...prev, title: e.target.value }))}
                onChange={(e) => handleInputChange(headlineKey, e.target.value)}
              />
            </div>
            <div className="input-container">
              <label className="input-label">Text</label>
              <ReactQuill
                ref={reactQuillRef}
                className="text-editor-input"
                value={productData[textKey]}
                onChange={(content, delta, source, editor) =>
                  handleEditorChange(content, delta, source, editor)
                }
                placeholder="Write something..."
                modules={modules}
                formats={formats}
              />
            </div>
          </div>
        </>
      )}
      {sectionClick?.price && (
        <>
          <div className="title-container">Price</div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Regular price</label>
              <input
                className="form-input"
                type="number"
                value={productPrice?.price}
                onChange={(e) => handlePriceChange("price", e.target.value)}
              />
            </div>
            <div className="sale-price-input-container">
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="input-label"
              >
                Sale price
                <Switch
                  style={{ marginLeft: "5px" }}
                  checked={showSalePrice}
                  onChange={handleHideSalePrice}
                />
              </label>
              {showSalePrice && (
                <input
                  className="form-input"
                  type="number"
                  value={productPrice?.salePrice}
                  onChange={(e) =>
                    handlePriceChange("salePrice", e.target.value)
                  }
                />
              )}
            </div>
            <div className="input-container">
              <label className="input-label">Gross Profit </label>
              {/* <input
                className="form-input"
                type="number"
                value={productPrice?.price}
                // onChange={(e) => handlePriceChange("price", e.target.value)}
              /> */}

              <div className="grossprofit-con">
                <span style={{ color: grossProfit < 0 ? "red" : "grey" }}>
                  $ {grossProfit === 0 || !grossProfit ? "-" : grossProfit}
                </span>
              </div>
            </div>
          </div>
        </>
      )}

      {selectImageInfo?.status && (
        <>
          <div className="title-container">
            <div className="flex-between">
              <span>Section media</span>
              <ConnectCanva
                handleCanva={handleCanva}
                isCanvaConnected={isCanvaConnected}
              />
            </div>
          </div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Media</label>

              <SectionEditImageContainer
                productImages={productImages}
                setProductImages={setProductImages}
                toUpdateImageDetails={toUpdateImageDetails}
                trueKey={selectImageInfo?.trueKey}
                imageUrl={
                  selectImageInfo?.trueKey === "section6Image"
                    ? textOnImageDetails.img
                    : sectionsImages[selectImageInfo?.trueKey]
                }
                sectionsImages={sectionsImages}
                setSectionsImages={setSectionsImages}
                setTextOnImageDetails={setTextOnImageDetails}
                aliexpressImages={aliexpressImages}
                isCanvaConnected={isCanvaConnected}
                awaitingRefresh={awaitingRefresh}
                setAwaitingRefresh={setAwaitingRefresh}
                isEditCanvaLoading={isEditCanvaLoading}
                setIsEditCanvaLoading={setIsEditCanvaLoading}
              />
            </div>
          </div>
        </>
      )}

      {Object.keys(sectionClick).some(
        (key) => key.includes("gallery") && sectionClick[key]
      ) && (
        <>
          <div className="title-container">
            <div className="flex-between">
              <span>Section media</span>
              <ConnectCanva
                handleCanva={handleCanva}
                isCanvaConnected={isCanvaConnected}
              />
            </div>
          </div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Media</label>
              {/* <Spin spinning={isEditCanvaLoading}> */}
              <DndProvider backend={HTML5Backend}>
                <GalleryEditImageContainer
                  imageCache={imageCache}
                  toUpdateImageDetails={toUpdateImageDetails}
                  productImages={productImages}
                  setProductImages={setProductImages}
                  sectionsImages={sectionsImages}
                  setSectionsImages={setSectionsImages}
                  aliexpressImages={aliexpressImages}
                  isCanvaConnected={isCanvaConnected}
                  awaitingRefresh={awaitingRefresh}
                  setAwaitingRefresh={setAwaitingRefresh}
                  isEditCanvaLoading={isEditCanvaLoading}
                  setIsEditCanvaLoading={setIsEditCanvaLoading}
                  {...{ setTestimonials, setReviews, setTextOnImageDetails }}
                />
              </DndProvider>
              {/* </Spin> */}
            </div>
          </div>
        </>
      )}
      {sectionClick?.productVariants && (
        <ProductVariantsComponent
          {...{ setReviews, setTestimonials, setTextOnImageDetails }}
          productImages={productImages}
          setProductImages={setProductImages}
          sectionsImages={sectionsImages}
          setSectionsImages={setSectionsImages}
          toUpdateImageDetails={toUpdateImageDetails}
          shipsFrom={shipsFrom}
          variantView={variantView}
          setVariantViewChange={setVariantViewChange}
          handleCanva={handleCanva}
          isCanvaConnected={isCanvaConnected}
          awaitingRefresh={awaitingRefresh}
          setAwaitingRefresh={setAwaitingRefresh}
          isEditCanvaLoading={isEditCanvaLoading}
          setIsEditCanvaLoading={setIsEditCanvaLoading}
        />
      )}
      {sectionClick?.tabsSection && (
        <ProTemplateTabsEditComponent {...{ tabsContent, setTabsContent }} />
      )}
      {sectionClick?.carouselSection && (
        <ProTemplateCarouselEditComponent
        {...{setReviews,
          setTestimonials,
          setTextOnImageDetails,
          setSectionsImages,}}
          isCanvaConnected={isCanvaConnected}
          awaitingRefresh={awaitingRefresh}
          setAwaitingRefresh={setAwaitingRefresh}
          isEditCanvaLoading={isEditCanvaLoading}
          setIsEditCanvaLoading={setIsEditCanvaLoading}
          testimonials={testimonials}
          setTestimonials={setTestimonials}
        />
      )}
      {sectionClick?.reviewsSection && (
        <PrecautionSectionEditComponent
          reviews={reviews}
          setReviews={setReviews}
          reviewKey={reviewKey}
        />
      )}
      {sectionClick?.reviewsIconSections && (
        <ProTemplateIconEditSection
        {...{setReviews,
          setTestimonials,
          setTextOnImageDetails,
          setSectionsImages,}}

          isCanvaConnected={isCanvaConnected}
          awaitingRefresh={awaitingRefresh}
          setAwaitingRefresh={setAwaitingRefresh}
          isEditCanvaLoading={isEditCanvaLoading}
          setIsEditCanvaLoading={setIsEditCanvaLoading}
          {...{ setReviews, reviews, setReviewKey }}
        />
      )}
      {sectionClick?.featuresSection && (
        <ProTemplateFeaturesEditComponent
          features={features}
          setFeatures={setFeatures}
        />
      )}
      {sectionClick?.featureHeadingSection && (
        <ProTemplateFeatuesHeadingEditComponent
          {...{ features, setFeatures }}
        />
      )}
      {sectionClick?.tableLabelSection && (
        <ProTemplateTableLabelEditComponent {...{ tableData, setTableData }} />
      )}
      {sectionClick?.tableHeadingSection && (
        <ProTemplateTableHeadingEditComponent
          {...{ tableData, setTableData }}
        />
      )}
      {sectionClick?.tableRowsSection && (
        <ProTemplateTableRowEditComponent
          {...{ tableData, setTableData, tableRowIndex, setTableRowIndex }}
        />
      )}
      {sectionClick?.addRowSection && (
        <ProTemplateAddRowEditComponent {...{ tableData, setTableData }} />
      )}
      {sectionClick?.companyNameSection && (
        <ProTemplateCompanyNameEditComponent
          {...{ companyName, setCompanyName }}
        />
      )}
      {sectionClick?.textOnImageSection && (
        <ProTemplateTextOnImageEditComponent
        {...{setReviews,
          setTestimonials,
          setTextOnImageDetails,
          setSectionsImages,}}
          isCanvaConnected={isCanvaConnected}
          awaitingRefresh={awaitingRefresh}
          setAwaitingRefresh={setAwaitingRefresh}
          isEditCanvaLoading={isEditCanvaLoading}
          setIsEditCanvaLoading={setIsEditCanvaLoading}
          {...{ textOnImageDetails, setTextOnImageDetails }}
        />
      )}
      {sectionClick?.collapseHeadingSection && (
        <ProTemplateCollpseHeadingEditComponent {...{ FAQData, setFAQData }} />
      )}
      {sectionClick?.collapseContainerSection && (
        <ProTemplateCollpseContentEditComponent
          {...{ FAQData, setFAQData, collapseIndex }}
        />
      )}
    </div>
  );
};

export default ProTemplateRightSection;

const ProTemplateFeatuesHeadingEditComponent = ({ features, setFeatures }) => {
  return (
    <>
      <div className="title-container">Features Heading</div>
      <div className="input-main-container">
        <div className="input-container">
          <label className="input-label">Heading</label>
          <input
            className="form-input"
            type="text"
            value={features?.heading}
            onChange={(e) =>
              setFeatures((prev) => ({ ...prev, heading: e.target.value }))
            }
          />
        </div>
      </div>
    </>
  );
};

// import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
