import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ProTemplateImageEdit from "./ProTemplateImageEdit";
import { message, Switch } from "antd";

const ProTemplateIconEditSection = ({
  setReviews,
  reviews,
  setTestimonials,
  setTextOnImageDetails,
  setSectionsImages,
  isCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  setReviewKey,
}) => {
  return (
    <>
      <div className="title-container ">
        Reviews icon's Setting
        {/* <img width="30px" src={trashbin} alt="delete" /> */}
      </div>
      <div className="input-main-container">
        {reviews.reviewImages.map((img, index) => (
          <div
            className="input-container w-full   flex !flex-row justify-between items-center"
            key={index}
          >
            <ProTemplateImageEdit
              {...{
                setReviews,
                setTestimonials,
                setTextOnImageDetails,
                setSectionsImages,
              }}
              image={img.img}
              setProductImages={setReviews}
              isCanvaConnected={isCanvaConnected}
              awaitingRefresh={awaitingRefresh}
              setAwaitingRefresh={setAwaitingRefresh}
              isEditCanvaLoading={isEditCanvaLoading}
              setIsEditCanvaLoading={setIsEditCanvaLoading}
              imageIndex={index}
              toWhich={"reviews"}
            />
            {/* <img
                        className="pro-tem-note-image cursor-pointer"
                        src={img.img}
                        alt={`note details image ${index}`}
                    /> */}

            <Switch
              className="pro-temp-table-switch-styles "
              checked={img.show}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(e) => {
                let numberOfEnabled = [];
                reviews.reviewImages.map((record, i) => {
                  if (record.show) {
                    numberOfEnabled.push(`${i + 1}`);
                  }
                });

                if (numberOfEnabled.length == 1 && !e) {
                  return message.info("Can not disable all reviews!");
                }
                console.log("eeeee", numberOfEnabled);

                setReviews((prevData) => {
                  const updatedData = { ...prevData };
                  updatedData.reviewImages[index].show =
                    !updatedData.reviewImages[index].show;
                  return updatedData;
                });
                console.log("eeeee data of reviews:", reviews.reviewImages);

                setReviewKey(
                  () =>
                    `${
                      reviews.reviewImages.findIndex((r) => r.show === true) + 1
                    }`
                );
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};
export default ProTemplateIconEditSection;
