import React from 'react'

const PetsKidsTemplateBuyersReviews = (
  {
    testimonialsSectionHeading,
    testimonialsSectionSubHeading,
 
    testimonialsText1,
 
    testimonialsText2,
   
    testimonialsText3,
    testimonialWriters,

    testimonialImage1,
    testimonialImage2,
    testimonialImage3,
    testimonialsSectionSubHeading2,
    testimonialsHeadingSection,
    testimonialsSubHeadingSection,
    testimonial1Section,
    testimonial2Section,
    testimonial3Section,
    handleSectionEditChange,
  }

) => {
  return (
    <div className="shopify-section"><section className="custom-features-section-type-2 my-[66px]" style={{ backgroundColor: '' }}>
      <div className="page-width  ">
        <div className='flex justify-center '>

          <h2
            onClick={() => handleSectionEditChange(["testimonialsHeadingSection"])}
            className={`custom-features-section-heading-type-2 cursor-pointer ${testimonialsHeadingSection ? "border border-2 border-white"
              : "border-dotted border-2 border-white"
              }`} style={{ color: '#000000' }}>
            {testimonialsSectionHeading}
          </h2>
        </div>
        <div 
            onClick={() => handleSectionEditChange(["testimonialsSubHeadingSection"])}
        
        className={`flex w-[80%] flex-col items-center mx-auto justify-center cursor-pointer ${testimonialsSubHeadingSection ? "border border-2 border-white"
              : "border-dotted border-2 border-white"
              }`}>
          <h3
             className={`custom-features-section-subheading-type-2 w-[60%]     `}
            style={{ color: 'white' }}>
            {testimonialsSectionSubHeading}
          </h3>
          <h3
            className={`custom-features-section-subheading-type-2 w-[60%] !font-semibold   `}
            style={{ color: 'white' }}>
            {testimonialsSectionSubHeading2}
          </h3>

        </div>
        <div className="custom-features-blocks-container-type-2" style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
          <div
            onClick={() => handleSectionEditChange(["testimonial1Section"])}

            className={`${testimonial1Section ? "border border-2 border-white"
              : "border-dotted border-2 border-white"

              } p-1 cursor-pointer`}>
            <div className="custom-feature-block-item-type-2">
              <div className="custom-feature-block-image-wrapper-type-2">
                <div className="custom-feature-block-image-type-2">
                  <img src={testimonialImage1} alt="Dummy Image" />
                </div>
              </div>
              <div className="custom-feature-block-heading-wrapper-type-2">
                <h3 className="custom-feature-block-heading-type-2">{testimonialWriters[1].name}</h3>
              </div>
              <div className="custom-feature-block-description-wrapper-type-2">
                <p className="custom-feature-block-description-type-2 line-clamp-3">
                  {testimonialsText1}
                </p>
              </div>
              <div className="custom-feature-block-text-wrapper-type-2">
                <div className="custom-feature-block-left-text-type-2" style={{ display: 'flex', alignItems: 'center' }}>
                  <p className='verified-text'>Verified Buyer</p>
                </div>
                <p className="custom-feature-block-right-text-type-2">
                {testimonialWriters[1].age}
                </p>
              </div>
            </div>
          </div>

          <div
            onClick={() => handleSectionEditChange(["testimonial2Section"])}

            className={`${testimonial2Section ? "border border-2 border-white"
              : "border-dotted border-2 border-white"

              } p-1 cursor-pointer`}>
            <div className="custom-feature-block-item-type-2">
              <div className="custom-feature-block-image-wrapper-type-2">
                <div className="custom-feature-block-image-type-2">
                  <img src={testimonialImage2} alt="Dummy Image" />
                </div>
              </div>
              <div className="custom-feature-block-heading-wrapper-type-2">
                <h3 className="custom-feature-block-heading-type-2">{testimonialWriters[2].name}</h3>
              </div>
              <div className="custom-feature-block-description-wrapper-type-2">
                <p className="custom-feature-block-description-type-2 line-clamp-3">
                  {testimonialsText2}
                </p>
              </div>
              <div className="custom-feature-block-text-wrapper-type-2">
                <div className="custom-feature-block-left-text-type-2" style={{ display: 'flex', alignItems: 'center' }}>
                  <p className='verified-text'>Verified Buyer</p>
                </div>
                <p className="custom-feature-block-right-text-type-2">{testimonialWriters[2].age}</p>
              </div>
            </div>
          </div>


          <div
            onClick={() => handleSectionEditChange(["testimonial3Section"])}

            className={`${testimonial3Section ? "border border-2 border-white"
              : "border-dotted border-2 border-white"

              } p-1 cursor-pointer`}>
            <div className="custom-feature-block-item-type-2">
              <div className="custom-feature-block-image-wrapper-type-2">
                <div className="custom-feature-block-image-type-2">
                  <img src={testimonialImage3} alt="Dummy Image" />
                </div>
              </div>
              <div className="custom-feature-block-heading-wrapper-type-2">
                <h3 className="custom-feature-block-heading-type-2">{testimonialWriters[3].name}</h3>
              </div>
              <div className="custom-feature-block-description-wrapper-type-2">
                <p className="custom-feature-block-description-type-2 line-clamp-3">
                  {testimonialsText3}
                </p>
              </div>
              <div className="custom-feature-block-text-wrapper-type-2">
                <div className="custom-feature-block-left-text-type-2" style={{ display: 'flex', alignItems: 'center' }}>
                  <p className='verified-text'>Verified Buyer</p>
                </div>
                <p className="custom-feature-block-right-text-type-2">{testimonialWriters[3].age}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>

  )
}

export default PetsKidsTemplateBuyersReviews