import { ArrowRightOutlined } from "@ant-design/icons";

const Banner = ({ allProductCount }) => {
  const goToUpgrade = () => {
    window.location.href = "/subscriptions";
  };

  return (
    <div
      className={`upgrade-banner rounded p-10 flex justify-between items-center w-full mt-4 ${
        allProductCount >= 3 ? "bg-[#FEF3F2] error" : "bg-[#F9F5FF]"
      }`}
    >
      <div className="flex flex-col justify-between gap-2">
        <span
          className={`text-xl font-semibold ${
            allProductCount >= 3 ? "text-[#B42318]" : "text-[#42307D]"
          }`}
        >
          Upgrade now for unlimited products
        </span>
        <span
          className={`text-lg font-normal ${
            allProductCount >= 3 ? "text-[#C64E41]" : "text-[#6941C6]"
          } `}
        >
          Start scaling your business today!
        </span>
      </div>
      <button className="upgrade-plan-btn" type="button" onClick={goToUpgrade}>
        Upgrade now
        <ArrowRightOutlined
          style={{
            fontSize: "12px",
            color: allProductCount >= 3 ? "#B42318" : "#5017D3",
            strokeWidth: "40",
            stroke: allProductCount >= 3 ? "#B42318" : "#5017D3",
          }}
        />
      </button>
    </div>
  );
};

export default Banner;
