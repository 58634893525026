import React, { useState, useEffect } from "react";
import "./MyProducts.css";
import Wrapper from "../Dashboard/Wrapper";
import ProductsStatusTable from "./ProductsStatusTable";
import { useDispatch, useSelector } from "react-redux";

import Banner from "./Banner";
import { callBackendAPI } from "../../helpers/apiCalls";
import ErrorBanner from "./ErrorBanner";

const MyProducts = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [importedProductsCount, setImportedProductsCount] = useState(0);
  const [statusMessage, setStatusMessage] = useState("");
  const [subscriptionApiLoaded, setSubscriptionApiLoaded] = useState(false);
  const dispatch = useDispatch();
  const { shopifyShopDetails } = useSelector((store) => store.authUser);
  const { connected, name } = shopifyShopDetails || {};

  const handleCheckSubsCriptionPlan = async () => {
    const endpoint = "store/check_subscription_status/";
    try {
      const response = await callBackendAPI(endpoint, "GET", {}, {});
      setSubscriptionStatus(
        response.override ? true : response?.status && !!response?.data?.length
      );
      setStatusMessage(response?.message);
      setImportedProductsCount(parseInt(response?.import_counts));
    } catch (error) {
    } finally {
      setSubscriptionApiLoaded(true);
    }
    console.log("status", subscriptionStatus, importedProductsCount);
  };

  // useEffect(() => {
  //   handleCheckSubsCriptionPlan();
  // }, []);
  console.log("connection", connected);

  return (
    <Wrapper>
      <div className="products-top-header">
        <h3>My Products</h3>
        {connected !== "false" ? (
          <h5>
            You are connected to <b>{name}</b>
          </h5>
        ) : (
          <h5>
            Shopify Store not connected! Please install Sofia App on your
            Shopify Store.
          </h5>
        )}
        {!subscriptionApiLoaded ? (
          <ErrorBanner />
        ) : statusMessage !== "Access token not found for the shop" &&
          connected !== "false" &&
          subscriptionStatus !== null &&
          !subscriptionStatus ? (
          <Banner allProductCount={importedProductsCount} />
        ) : (
          <></>
        )}
        <ProductsStatusTable
          subscriptionStatus={subscriptionStatus}
          handleCheckSubsCriptionPlan={handleCheckSubsCriptionPlan}
          showLimitBar={
            subscriptionApiLoaded  
          }
          importedProductsCount={importedProductsCount}
        />
        <div className="bg-black"></div>
      </div>
    </Wrapper>
  );
};

export default MyProducts;
