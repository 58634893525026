import React from 'react'

const PetsKidsTemplateSeventhSection = (
    {
        saleHeadline,
        saleSubSections,

        saleHeadlineSection,
        sectionClick,

        handleSectionEditChange,
    }
) => {
    return (
        <section className="scribblesmart-section-template--24179347915097__image_with_content_type_6_apFHTr w-full">
            <div className="page-width">
                <div className='flex justify-center'>

                </div>
                <h2
                    onClick={() => handleSectionEditChange(["saleHeadlineSection"])}
                    className={` cursor-pointer section-title  ${saleHeadlineSection ? "border border-2 border-white"
                        : "border-dotted border-2 border-white"} `}>{saleHeadline}</h2>
                <div className="blocks-wrapper">
                    {
                      saleSubSections &&    saleSubSections.map((sale, index) => {
                            return (
                                <div key={index}
                                    onClick={() => handleSectionEditChange([`saleSubSections${index + 1}`])}
                                    className={`cursor-pointer content-block ${sectionClick[`saleSubSections${index + 1}`] ? "border border-2 border-white"
                                        : "border-dotted border-2 border-white"}`}>
                                    <img src="//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/clock.png?v=1730810344&width=60" alt srcSet="//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/clock.png?v=1730810344&width=60 60w" width={60} height={60} loading="lazy" />
                                    <h3 className="block-heading blk-head-content_block_d7kbUB">{sale?.question}</h3>
                                    <p className="block-description blk-desc-content_block_d7kbUB font-medium">`{sale?.answerTitle}`</p>
                                    <p  className="block-description blk-desc-content_block_d7kbUB font-normal"  >
                                        {sale?.answerText}
                                    </p>
                                </div>
                            )
                        })
                    }


                </div>
            </div>
        </section>

    )
}

export default PetsKidsTemplateSeventhSection